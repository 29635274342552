import { Box, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@mui/material';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BASE_URL } from '../../../../environment';
import CountUp from 'react-countup';
import SimpleCard from "../../../../containers/Dashboard/Funds/card";
import FundSkeleton from '../../../../containers/Dashboard/Funds/CommonLoader/FundSkeleton';
import LumsumGoalsFundForm from '../../../../containers/Goals/lumpsumGoalsFund/LumsumGoalsFundForm';
import AmountModal from '../../../../containers/Goals/modal/AmountModal';
import OneTimeAmountModal from './Modal/OneTimeAmountModal';


const FundBasketFundDataCard = ({ allFunds, fetchData, hasMore, classes, handleInvest, loading, setStep }) => {

    const [showFundPopup, setShowFundPopup] = useState(false)

    const LoaderComp = () => {
        return (
            <>

                {[1, 2, 3].map((key, index) => (
                    <div key={key}>
                        <FundSkeleton />
                    </div>
                ))}
            </>
        );
    };

    const handleSetFund = async (data) => {

    }

    const handleInvestmentPopup = () => {
        setShowFundPopup(true)
    }

    const close_contact = ( data) => {
        console.log(data)
        setStep(1, data)
        setShowFundPopup(false)
    }

    const open_success = () => {

    }
    return (
        <div>
            <div >
                <InfiniteScroll
                    style={{
                        overflow: "hidden"
                    }}
                    dataLength={allFunds?.length}
                    next={fetchData}
                    hasMore={hasMore}
                    loader={<LoaderComp />}
                    endMessage={
                        <Typography className={classes.text}>
                            {/* No More Funds to display */}
                        </Typography>
                    }
                >

                    {allFunds.map((fund, index) => {
                        return <SimpleCard key={index} fund={fund} access={'recomended'} handleSetFund={handleSetFund} />;
                    })}
                </InfiniteScroll>
                {(allFunds.length === 0 && !loading) && <Typography variant="body1" style={{ textAlign: 'center', marginTop: '20px', fontSize: '0.7rem' }}>No Funds Found</Typography>}

            </div>
            {!loading && <Box display={'flex'} justifyContent={'center'}>
                <button className={classes.primaryButton} onClick={handleInvestmentPopup}>
                    Invest
                </button>
            </Box>
            }
            <OneTimeAmountModal
                selectedFund={allFunds}
                showModal={showFundPopup}
                handleCloseModal={close_contact}
                handleShowSuccess={open_success}
            />
        </div>
    );
};

export default FundBasketFundDataCard;