import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    makeStyles,
    CircularProgress,
    Checkbox,
    Switch,
    FormControlLabel,
    MenuItem, Select, createTheme,
    Tooltip,
} from "@material-ui/core";
// import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
// import { CREATE_SIP } from "../../../utils/endpoints";
import httpInstance from "../../../../utils/instance";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { BorderColor, BorderRight, BorderTop, Code } from "@mui/icons-material";
import axios from "axios";
import {
    CREATE_SIP,
    GET_CONSENT_SIP_OTP,
    VERIFY_CONSENT_SIP_OTP,
    MANDATES_DETAILS_OF_BANK,
    GET_ALL_BANK,
    POST_TRANSACTION_BASKETS,
    POST_TRANSACTION_BASKETS_GENERATE_CONSENT,
    VERIFY_AND_VALIDATE_BASKET_CONSENT_SIP_OTP,
} from "../../../../utils/endpoints";
import { namespace, BASE_URL } from "../../../../environment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { set } from "date-fns";
import { Skeleton } from "@mui/material";
import Sliders from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ErrorMessage from "../../../Validation/ErrorMessage";
import SipDatePicker from "../../SipDatePicker";
import EMandate from "../../../../containers/MutualFund/InvestType/EMandate";
import OTPInput from "react-otp-input";
import CustomToast from "../../../UI/CustomToast";


const settings = {
    dots: true, // Enable navigation dots
    infinite: false, // Prevent infinite scrolling
    speed: 500, // Transition speed
    slidesToShow: 1, // Number of items to show in view
    slidesToScroll: 1, // Number of items to scroll at a time
    // appendDots: dots => (
    //     <div
    //       style={{
    //         // backgroundColor: "#ddd",
    //         borderRadius: "10px",
    //         // padding: "10px"
    //       }}
    //     >
    //       <ul style={{ margin: "0px" }}> 1{dots} </ul>
    //     </div>
    //   ),
    responsive: [
        {
            breakpoint: 768, // For tablets and smaller
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 480, // For mobile devices
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};


const FundBasketFundOTP = ({ fundData, fpData, kycStatus, show_banner, kycData, fundTransactionBasket, handleStepOTP, handleStepsOTP, state }) => {

    // console.log(fundData, fundTransactionBasket)

    const useStyles = makeStyles((theme) => ({
        root: {
            "& .MuiSlider-valueLabel": {
                "& > *": {
                    backgroundColor: "white",
                    color: "black",
                },
            },
        },
        rupeesSymbol: {
            position: 'relative',
            right: '70px',
            top: '32px',
            marginRight: '5px',
            fontSize: '13px',
            fontFamily: "Open Sans",

        },
        inputBox: {
            // background: "#E6E6E6",
            background: "#fff",
            padding: "0.7rem 0.95rem",
            // borderRadius: "10px",
            border: "none",
            borderBottom: "1px solid #000",
            width: "-webkit-fill-available",
            fontFamily: "inherit",
            paddingLeft: '20px',
            outline: "none",
            caretColor: "#0E76BC",
        },
        label: {
            fontSize: "0.7rem",
            fontWeight: 500,
        },
        primaryButton: {
            padding: "0.8rem 3rem",
            background: "#0E76BC",
            color: "#fff",
            fontSize: "0.8rem",
            fontWeight: 600,
            fontFamily: "inherit",
            borderRadius: "45px",
            border: "none",
            cursor: "pointer",
            // Styling for the disabled state
            '&:disabled': {
                opacity: '0.7',
                cursor: 'not-allowed',
            },
        },
        secondaryButton: {
            padding: "0.2rem 1rem",
            // background: "#0E76BC",
            color: "#0E76BC",
            fontSize: "0.8rem",
            fontWeight: 600,
            fontFamily: "inherit",
            borderRadius: "45px",
            border: "none",
            cursor: "pointer",
            // Styling for the disabled state
            '&:disabled': {
                opacity: '0.7',
                cursor: 'not-allowed',
            },
        },
        thumb: {
            background: "#0E76BC;",
            width: "20px",
            height: "20px",
            boxShadow: "none !important",
            " &.Mui-focusVisible": {
                boxShadow: "none !important",
            },
            "&.Mui-active": {
                boxShadow: "none !important",
            },
        },
        mark: {
            background: "black",
        },
        rail: {
            background: "#cccc;",
            height: "10px",
            borderRadius: "10px",
        },
        track: {
            background: "#0E76BC;",
            height: "10px",
            borderRadius: "10px",
        },
        switchButtonNew: {
            "& .MuiFormControlLabel-label": {
                fontSize: '0.9rem',
            },
            "& .Mui-checked.Mui-disabled+.MuiSwitch-track": {
                background: "#0E76BC !important",
            },
            "& .MuiSwitch-switchBase.Mui-disabled .MuiSwitch-thumb": {
                opacity: 0.7,
            }
        },
        checkBox: {
            "& .MuiSvgIcon-root": {
                height: "0.9em",
            },
            // "& .MuiTypography-body1": {
            //     fontSize: "0.75rem",
            // },
            "& .MuiCheckbox-colorPrimary.Mui-checked": {
                color: "#0E76BC",
            },
        },
        text: {
            fontSize: "0.8rem",
            fontWeight: 700,
        },
        mainText: {
            fontSize: "0.9rem",
            fontFamily: "inherit",
            fontWeight: 600,
            paddingLeft: '5px',
        },
        subText: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
            fontWeight: 500,
            paddingLeft: '5px',
        },
        textblue: {
            fontSize: "0.9rem",
            fontWeight: 500,
            color: "#0E76BC",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
        },
        textgrey: {
            fontSize: "0.7rem",
            color: "#676767",
            marginBottom: "10px",
        },
        text3: {
            fontSize: "0.7rem",
            color: "#676767",
        },
        Select: {
            // background: "#E6E6E6",
            background: "#fff",
            padding: "0.4rem 0.95rem",
            // borderRadius: "10px",
            // border: "none",
            borderBottom: "1px solid #000",
            boxSizing: "border-box",
            textAlign: "left",
            width: "100%",
            minWidth: "150px",
            fontFamily: "inherit !important"
        },
        selectItem: {
            fontSize: "0.85rem !important",
            fontFamily: "inherit !important",
        },
        // switchButton: {
        //   "& .Mui-checked": {
        //     color: "#0E76BC", // Change this to your desired color
        //   },
        //   "& .MuiSwitch-track": {
        //     backgroundColor: "#0E76BC !important", // Change this to your desired track color
        //   },
        // },
        rupees: {
            fontFamily: "Open Sans",
        },
        recommendedFoundAreaFundSlide: {
            border: '1px solid #E4E4E4',
            margin: "0 10px",
            padding: '5px',
            borderRadius: '10px'
        },
        recommendedFoundAreaTopHead: {

        },
        recommendedFoundAreaTopHeadText: {
            fontSize: '0.6rem',
            border: '1px solid #E4E4E4',
            backgroundColor: "#E4E4E4",
            padding: '0.2rem 0.3rem',
            borderRadius: "5px 5px 0px 0px",
            textTransform: "capitalize !important",
        },
        recommendedFoundArea: {
            border: '1px solid #E4E4E4',
            padding: '0.5rem',
            borderRadius: "10px",
        },
        backButton: {
            // marginTop: '10px',
            // padding: "5px",
            justifyContent: "flex-start",
            cursor: 'pointer',
        },
        bankSelection: {
            border: "1px solid #cccc",
            cursor: 'pointer',
            marginBottom: '10px',
            position: 'relative',
            padding: '10px',
            borderRadius: '10px',
            "&:hover": {
                border: "1px solid #0E76BC",

            }
        },
        bankSelectionActive: {
            border: "1px solid #0E76BC",
        },
        sliderCarousel: {
            "& .slick-dots li button:before": {
                color: "#777777",
                backgroundColor: "#777777",
                // padding: "0px 5px",
                margin: "0px 5px",
                borderRadius: '3px',
                height: '12px',
            }
        },
    }));




    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        marginRight: "10px",

        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#0E76BC',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : "#fff"
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
            border: "4px solid #fff",
            backgroundColor: "#0E76BC"
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),

        },

    }));


    const classes = useStyles();

    const [apiError, setApiError] = useState("");
    const [error, setError] = useState("");
    const [siperror, setSipError] = useState("");
    // const [stepUpAmountError, setFormError] = useState("")
    const [stepFormError, setFormError] = useState("")
    const [selectBankError, setSelectBankError] = useState("")
    const [amount, setAmount] = useState("");
    const [stepUpAmount, setStepUpAmount] = useState("");
    const [loading, setLoading] = useState(false);
    const [installments, setInstallments] = useState(60);
    const [autoMandateSip, setAutoMandateSip] = useState(true);
    const [noMandateSip, setNoMandateSip] = useState(false);
    const [sipDate, setSipDate] = useState("");
    const [nextSip, setNextSip] = useState("");
    // const [handleStepsOTP, setHandleStepsOTP] = useState(0);
    const { userStore } = useSelector((state) => state);
    const navigate = useNavigate();
    const [otp, SetOTP] = useState("");
    const [consentId, SetConsentId] = useState((fundData?.stateValue?.transaction_basket_items[0]?.id && fundData?.stateValue?.transaction_basket_items[0]?.transaction_type === "lumpsum") ? fundData?.stateValue?.transaction_basket_items[0]?.id : null);
    const { code } = useParams();
    const [timer, setTimer] = useState(30);
    const [isTimerActive, setIsTimerActive] = useState(true);
    const [otpHasBeenSent, setotpHasBeenSent] = useState(true);
    const [bankList, setBankList] = useState(null)
    const [mandateList, setMandateList] = useState(null)
    const [selectedBank, setSelectedBank] = useState('')
    const [eMandateTab, seteMandateTab] = useState('');
    const [mandate, setMandate] = useState(false);
    const [selectedSipFrequencyInput, setSelectedSipFrequencyInput] = useState('monthly'); // Default to 'amount'
    const [selectedSipFrequencyStepUpInput, setSelectedSipFrequencyStepUpInput] = useState('yearly')

    console.log(fundData)


    const handle_otp_value = (data) =>{
        SetOTP(data)
    }

    // --------------------------------------- handle confirm sip order after bank selection ------------------------//
    const handle_confirm_fundBasket_order = async () => {
        if (
            userStore?.user?.id == "" ||
            userStore?.user?.id == "undefined" ||
            userStore?.user?.id == null
        ) {
            navigate(`${namespace}/user`);
        }
        // console.log("kycStatus", kycStatus, kycData);
        // console.log(kycData)
        if ((kycData?.status == 'nominee' && kycData?.is_kyc_compliant == true && kycStatus == 'pending') || (!kycData?.is_kyc_compliant && kycStatus == "successful")) {

            try {
                setSelectBankError("");
                // setHandleStepsOTP(2);
                // handleStepOTP(2)

                const transactionFund = fundTransactionBasket.map((data, index) => {
                    return {
                        transaction_type: "lumpsum",
                        fund_isin: data?.fund_isin,
                        folio_number: fundData?.folio_numbe ? fundData?.folio_numbe : null,
                        amount: data?.amount,
                        // step_up_amount: null,
                        // step_up_frequency: null,
                        // installment_day: sipDate[index],
                        // frequency: data?.frequency,
                        // payment_method: data?.payment_method || 'mandate',
                        // payment_source: selectedBank?.mandate_id ? selectedBank?.mandate_id : null,
                    }
                })


                const formData = {

                    user_id: userStore?.user?.id,

                    transaction_basket_items: transactionFund,
                    model_portfolio_id: state?.id,

                }
                // console.log(formData, fundTransactionBasket)
                const httpConfig = {
                    method: "POST",
                    tokenType: "user",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: formData,
                };

                // console.log(httpConfig)
                var idSet = consentId
                if (!consentId) {
                    const response = await httpInstance(
                        `${POST_TRANSACTION_BASKETS}`,
                        httpConfig
                    );
                    // console.log(response?.data?.data)
                    SetConsentId(response.data?.data.id);

                    idSet = response.data.data.id
                }
                const httpConfig1 = {
                    method: "POST",
                    tokenType: "user",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: { transaction_basket_id: idSet },
                }
                // console.log(httpConfig1)
                handle_transaction_basket_generate_consent(httpConfig1)

            }
            catch (error) {
                setApiError(error.response?.data?.message ?? error.response?.data?.error ?? "Somthing went wrong")
                SetOTP("");

            }
        }

    }


    const handle_transaction_basket_generate_consent = async (httpConfig1) => {
        try {
            const responseGenerate = await httpInstance(
                `${POST_TRANSACTION_BASKETS_GENERATE_CONSENT}`,
                httpConfig1
            );
            // console.log(responseGenerate, 'responseGenerate')
            // SetConsentId(response.data?.data.id);
            // handle_lumsum_otp(response.data?.data.id);
            SetOTP("");

            var timeLeft = 30; // set the time limit in seconds
            var timer = setInterval(function () {
                setIsTimerActive(true);
                setotpHasBeenSent(true);
                timeLeft--;
                setTimer(timeLeft);

                if (timeLeft <= 0) {
                    clearInterval(timer);
                    setotpHasBeenSent(false);
                    setIsTimerActive(false);
                }
            }, 1000); // set the interval to 1000 milliseconds (1 second)

        } catch (error) {
            setApiError(error.response?.data?.message ?? error.response?.data?.error ?? "Somthing went wrong")
            CustomToast(error.response?.data?.message ?? error.response?.data?.error ?? "Somthing went wrong", 'FAILED')
            SetOTP("");
        }
    }

    //----------------------submit sip----------------------------------------------------//
    const handle_OTP = async () => {
        // if (sessionStorage.getItem("mpin")) {
        // if (kycStatus == "successful") {
        if ((kycData?.status == 'nominee' && kycData?.is_kyc_compliant == true && kycStatus == 'pending') || (!kycData?.is_kyc_compliant && kycStatus == "successful")) {

            // let min_sip = fpData?.min_sip_amount > 1000 ? fpData?.min_sip_amount : 1000;
            let min_sip = fpData?.min_sip_amount;
            // if (amount < min_sip) {
            //     setApiError(`Minimum SIP Investment is ₹${min_sip}`);
            // } else if (!sipDate) {
            //     // setError("");
            //     setApiError("Select SIP date");
            // } else {
                setError("");
                setSipError("");
                setLoading(true);
                setApiError("");

                //----------------store data--------------------
                const formDataOTP = {
                    transaction_basket_id: consentId,
                    otp: otp,
                };

                const httpConfigOTP = {
                    method: "POST",
                    tokenType: "user",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: formDataOTP,
                };

                const formData = {
                    userId: userStore.user?.id,
                    transactionTypeId: 2,
                    fundPlanId: fundData?.scheme_code,
                    fundSubPlanId: null,
                    fundFolioNumber: fundData?.folio_number
                        ? fundData?.folio_number
                        : null,
                    fundAmount: amount,
                    numberOfInstallments: autoMandateSip ? 1200 : installments,
                    frequency: "MONTHLY",
                    isin: fundData?.isin,
                    startDate: sipDate,
                    nav: fundData?.nav,
                    isWeb: true,
                    amcId: fundData?.amc_code,
                    planName: fundData?.scheme_name,
                    mfSipConsentId: consentId,
                };

                const httpConfig = {
                    method: "POST",
                    tokenType: "user",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: formData,
                };

                try {
                    const responseOTP = await httpInstance(
                        `${VERIFY_AND_VALIDATE_BASKET_CONSENT_SIP_OTP}`,
                        httpConfigOTP
                    );
                    // console.log(responseOTP)
                    // console.log({
                    //       orderData: responseOTP.data?.data,
                    //       type: "lumpsum",
                    //       schemeCode: fundData?.id,
                    //       fundData: fundData,
                    //       consentId: consentId,
                    //     })
                    if (responseOTP?.data?.data?.transaction_basket_items?.[0]?.status === 'failed') {
                        setLoading(false);
                        setApiError(responseOTP?.data?.data?.transaction_basket_items?.[0]?.response_message);
                        setTimeout(() => {
                            // setHandleStepsOTP(0);
                            handleStepOTP(0)
                            setApiError("");
                        }, 3000)
                        // clearInterval(timer);
                        // setotpHasBeenSent(false);
                        // setIsTimerActive(false);

                    } else {

                        navigate(`${namespace}/mutualfund/order-summary`, {
                            state: {
                                orderData: responseOTP.data?.data,
                                type: "lumpsum",
                                schemeCode: fundData?.id,
                                fundData: fundData,
                                consentId: consentId,
                            },
                        });
                    }
                    // if (responseOTP["data"]["responseStatus"] == "Success") {
                    //   const response = await httpInstance(`${CREATE_SIP}`, httpConfig);
                    //   sessionStorage.setItem(
                    //     "lumpsum",
                    //     JSON.stringify({
                    //       name: fundData?.scheme_name,
                    //       img: fundData?.img_url,
                    //     })
                    //   );
                    //   navigate(`${namespace}/mutualfund/order-summary`, {
                    //     state: {
                    //       orderData: response.data?.data,
                    //       type: "lumpsum",
                    //       schemeCode: fundData?.scheme_code,
                    //     },
                    //   });
                    //   setHandleStepsOTP(0);
                    //   //window.location.replace(response.data?.data?.tokenUrl);
                    // } else {
                    //   setApiError(responseOTP.message?.message);
                    //   setHandleStepsOTP(0);
                    // }
                } catch (error) {
                    if (
                        error.response.data?.message.includes(
                            `Mandate Account not created`
                        )
                    ) {
                        setMandate(true);
                    } else {
                        setApiError(error.response.data?.message);
                    }

                    setLoading(false);
                }
            // }
        } else {
            show_banner();
            setApiError(
                kycStatus == "submitted"
                    ? "Your KYC is under review"
                    : "Please complete your KYC"
            );
        }
        // } else {
        //   navigate(`${namespace}/user`);
        // }
    };

    //----------------------validate Sip Dates----------------------------------------------------//

    const validateSipDates = () => {
        const hasEmptyDates = sipDate.some((date) => date === null || date === undefined || date === "");
        if (hasEmptyDates) {
            return true;
        }
        return false;
    };


    //----------------------Continue after sip Dates----------------------------------------------------//

    const handle_SIP = async () => {
        if (
            userStore?.user?.id == "" ||
            userStore?.user?.id == "undefined" ||
            userStore?.user?.id == null
        ) {
            navigate(`${namespace}/user`);
        }

        // if (
        //     amount <
        //     (fundData?.folio_number
        //         ? fpData?.min_additional_investment
        //         : fpData?.min_initial_investment)
        // ) {
        //     setError(
        //         `Minimum Investment is ₹${fundData?.folio_number
        //             ? fpData?.min_additional_investment
        //             : fpData?.min_initial_investment
        //         }`
        //     );
        // } else if (!sipDate) {
        //     setError("");
        //     setSipError("Select SIP date");
        // } else if (autoMandateSip && !stepUpAmount) {
        //     setError("");
        //     setSipError("");
        //     setFormError("Please enter step up amount");
        // } 
        setApiError("")
        if (!autoMandateSip && !noMandateSip) {
            setApiError("Select any one to continue the flow");
            if (!sipDate) {
                setSipError("Select SIP date ");

            }
        }
        else if (!sipDate) {
            setSipError("Select SIP date ");

        } else {
            setError("");
            setSipError("");
            setFormError("");
            if (noMandateSip) {
                // setHandleStepsOTP(2)
                handle_confirm_fundBasket_order()
            } else {
                // setHandleStepsOTP(1);

                // const dataset = sipDate.map((item, index) => {
                //     console.log(item == undefined)
                //     return !item
                // })
                // console.log(dataset, dataset.length, sipDate[0])
                // // if(sip)
                // if (sipDate?.length !== fundTransactionBasket?.length && !dataset) {
                //     setApiError("Fill all the SIP's date");
                // } else {
                //     // setHandleStepsOTP(1);

                // }

                // console.log(sipDate, 'sipDate')
                // console.log("SIP Dates:", sipDate);

                if (validateSipDates()) {
                    console.log("SIP Dates:", sipDate);
                    // Proceed with the next step or form submission
                    setApiError("Fill all the SIP's date");
                } else {
                    // setHandleStepsOTP(1);
                    handleStepOTP(1)
                }
            }

            // const httpConfig = {
            //   method: "GET",
            //   tokenType: "user",
            //   headers: {
            //     "Content-Type": "application/json",
            //   },
            // };

            // const response = await httpInstance(`${GET_CONSENT_SIP_OTP}`, httpConfig);
            // SetOTP("");
            // SetConsentId(response.data?.data.id);

            // var timeLeft = 30; // set the time limit in seconds
            // var timer = setInterval(function () {
            //   setIsTimerActive(true);
            //   setotpHasBeenSent(true);
            //   timeLeft--;
            //   setTimer(timeLeft);

            //   if (timeLeft <= 0) {
            //     clearInterval(timer);
            //     setotpHasBeenSent(false);
            //     setIsTimerActive(false);
            //   }
            // }, 1000); // set the interval to 1000 milliseconds (1 second)
        }
    };


    useEffect(() => {
        if (handleStepsOTP === 2 && userStore?.user?.id) {
            handle_confirm_fundBasket_order()
        }

    }, [handleStepsOTP, userStore, kycData])






    return (
        <div>
            < Box m={3} p={3}>
                <Box display={'flex'} justifyContent={'center'} alignItems={'start'} flexDirection={'column'} my={0}>
                    <img src="/images/otpScreen.svg" alt="otp" height={100} width={100} />
                    <Typography variant="h6">Confirm your order with OTP</Typography>
                </Box>
                <label className={classes.label}>Enter OTP</label>
                {/* <input
            className={classes.inputBox}
            type="text"
            placeholder="Enter One Time Password"
            onChange={handle_otp_value}
            value={otp}
          /> */}

                <OTPInput
                    value={otp}
                    onChange={handle_otp_value}
                    numInputs={4}
                    renderSeparator={<span style={{ width: "8px" }}></span>}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={{
                        border: "none",
                        borderRadius: "0px",
                        borderBottom: "1px solid #000",
                        width: "40px",
                        height: "40px",
                        fontSize: "13px",
                        color: "#000",
                        fontWeight: "400",
                        caretColor: "blue",
                    }}
                    focusStyle={{
                        border: "none",
                        borderRadius: "0px",
                        borderBottom: "1px solid #000", // Ensure this matches inputStyle
                        background: "#E6E6E6",
                        outline: "none",
                    }}
                />

                <div className="resendOTP">
                    {otpHasBeenSent ? (
                        <></>
                    ) : (
                        <>
                            <p className="otpWillRecivie">
                                OTP has been sent you will receive shortly
                            </p>
                        </>
                    )}
                    {isTimerActive ? (
                        <div>
                            <p className="resendOTPTimer">Resend OTP in {timer} seconds</p>
                        </div>
                    ) : (
                        <div>
                            <p className="resendOTPRequest">
                                Didn't receive OTP?{" "}
                                <span style={{color: 'blue'}} onClick={() => handle_confirm_fundBasket_order(consentId)}>
                                    Resend OTP
                                </span>
                            </p>
                        </div>
                    )}
                </div>

                {apiError && <ErrorMessage message={apiError} />}
                <button
                    className={classes.primaryButton}
                    onClick={handle_OTP}
                    style={{ marginTop: "10px" }}
                >
                    {loading ? (
                        <CircularProgress size="1rem" color="inherit" />
                    ) : (
                        "Verify OTP"
                    )}
                </button>
                <div>{error && <ErrorMessage message={error} />}</div>
            </Box>



        </div >
    );
};

export default FundBasketFundOTP;
