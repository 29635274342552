import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  makeStyles,
  CircularProgress,
  Slider,
  Checkbox,
  Switch,
  FormControlLabel,
  MenuItem, Select, createTheme,
  Tooltip,
} from "@material-ui/core";
// import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import ErrorMessage from "../../../components/Validation/ErrorMessage";
import SipDatePicker from "../../../components/MutualFund/SipDatePicker";
// import { CREATE_SIP } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import number_ordinals from "../../../hooks/NumberOrdinal";
import MandateAlert from "./MandateAlert";
import { BorderColor, Code } from "@mui/icons-material";
import axios from "axios";
import {
  CREATE_SIP,
  GET_CONSENT_SIP_OTP,
  VERIFY_CONSENT_SIP_OTP,
  MANDATES_DETAILS_OF_BANK,
  GET_ALL_BANK,
  POST_TRANSACTION_BASKETS,
  POST_TRANSACTION_BASKETS_GENERATE_CONSENT,
  VERIFY_AND_VALIDATE_BASKET_CONSENT_SIP_OTP,
} from "../../../utils/endpoints";
import { namespace, BASE_URL } from "../../../environment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import EMandate from "./EMandate";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OTPInput from "react-otp-input";
import CustomToast from "../../../components/UI/CustomToast";

const Sip = ({ fundData, fpData, kycStatus, show_banner, kycData }) => {

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiSlider-valueLabel": {
        "& > *": {
          backgroundColor: "white",
          color: "black",
        },
      },
    },
    rupeesSymbol: {
      position: 'relative',
      right: '70px',
      top: '32px',
      marginRight: '5px',
      fontSize: '13px',
      fontFamily: "Open Sans",

    },
    inputBox: {
      // background: "#E6E6E6",
      background: "#fff",
      padding: "0.7rem 0.95rem",
      // borderRadius: "10px",
      border: "none",
      borderBottom: "1px solid #000",
      width: "-webkit-fill-available",
      fontFamily: "inherit",
      paddingLeft: '20px',
      outline: "none",
      caretColor: "#0E76BC",
    },
    label: {
      fontSize: "0.7rem",
      fontWeight: 500,
    },
    primaryButton: {
      padding: "0.8rem 3rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      fontFamily: "inherit",
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      border: "1px solid #0E76BC",
      '&:hover': {
          backgroundColor: "#fff",
          color: "#0E76BC",
      },  
      // Styling for the disabled state
      '&:disabled': {
        opacity: '0.7',
        cursor: 'not-allowed',
      },
    },
    secondaryButton: {
      padding: "0.2rem 1rem",
      // background: "#0E76BC",
      color: "#0E76BC",
      fontSize: "0.8rem",
      fontWeight: 600,
      fontFamily: "inherit",
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      // Styling for the disabled state
      '&:disabled': {
        opacity: '0.7',
        cursor: 'not-allowed',
      },
    },
    thumb: {
      background: "#0E76BC;",
      width: "20px",
      height: "20px",
      boxShadow: "none !important",
      " &.Mui-focusVisible": {
        boxShadow: "none !important",
      },
      "&.Mui-active": {
        boxShadow: "none !important",
      },
    },
    mark: {
      background: "black",
    },
    rail: {
      background: "#cccc;",
      height: "10px",
      borderRadius: "10px",
    },
    track: {
      background: "#0E76BC;",
      height: "10px",
      borderRadius: "10px",
    },
    checkBox: {
      "& .MuiSvgIcon-root": {
        height: "0.9em",
      },
      "& .MuiTypography-body1": {
        fontSize: "0.75rem",
      },
      "& .MuiCheckbox-colorPrimary.Mui-checked": {
        color: "#0E76BC",
      },
    },
    text: {
      fontSize: "0.8rem",
      fontWeight: 700,
    },
    mainText: {
      fontSize: "0.9rem",
      fontFamily: "inherit",
      fontWeight: 600,
      paddingLeft: '5px',
    },
    subText: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
      paddingLeft: '5px',
    },
    textblue: {
      fontSize: "0.9rem",
      fontWeight: 500,
      color: "#0E76BC",
      display: "flex",
      alignItems: "center",
      marginLeft: "10px",
    },
    textgrey: {
      fontSize: "0.7rem",
      color: "#676767",
      marginBottom: "10px",
    },
    Select: {
      // background: "#E6E6E6",
      background: "#fff",
      padding: "0.4rem 0.95rem",
      // borderRadius: "10px",
      // border: "none",
      borderBottom: "1px solid #000",
      boxSizing: "border-box",
      textAlign: "left",
      width: "100%",
      minWidth: "150px",
      fontFamily: "inherit !important"
    },
    selectItem: {
      fontSize: "0.85rem !important",
      fontFamily: "inherit !important",
    },
    // switchButton: {
    //   "& .Mui-checked": {
    //     color: "#0E76BC", // Change this to your desired color
    //   },
    //   "& .MuiSwitch-track": {
    //     backgroundColor: "#0E76BC !important", // Change this to your desired track color
    //   },
    // },
    rupees: {
      fontFamily: "Open Sans",
    },
  }));




  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    marginRight: "10px",

    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#0E76BC',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : "#fff"
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
      border: "4px solid #fff",
      backgroundColor: "#0E76BC"
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),

    },
  }));


  const classes = useStyles();

  const [apiError, setApiError] = useState("");
  const [error, setError] = useState("");
  const [siperror, setSipError] = useState("");
  const [stepUpAmountError, setStepUpAmountError] = useState("")
  const [selectBankError, setSelectBankError] = useState("")
  const [amount, setAmount] = useState("");
  const [stepUpAmount, setStepUpAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [installments, setInstallments] = useState(60);
  const [cancellation, setCancellation] = useState(false);
  const [noMandateSip, setNoMandateSip] = useState(false);
  const [sipDate, setSipDate] = useState("");
  const [nextSip, setNextSip] = useState("");
  const [handleStepsOTP, setHandleStepsOTP] = useState(0);
  const { userStore } = useSelector((state) => state);
  const navigate = useNavigate();
  const [otp, SetOTP] = useState("");
  const [consentId, SetConsentId] = useState((fundData?.stateValue?.transaction_basket_items[0]?.id && fundData?.stateValue?.transaction_basket_items[0]?.transaction_type === "sip") ? fundData?.stateValue?.transaction_basket_items[0]?.id : null);
  const { code } = useParams();
  const [timer, setTimer] = useState(30);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [otpHasBeenSent, setotpHasBeenSent] = useState(true);
  const [bankList, setBankList] = useState(null)
  const [mandateList, setMandateList] = useState(null)
  const [selectedBank, setSelectedBank] = useState('')
  const [eMandateTab, seteMandateTab] = useState('');
  const [mandate, setMandate] = useState(false);
  const [selectedSipFrequencyInput, setSelectedSipFrequencyInput] = useState('monthly'); // Default to 'amount'
  const [selectedSipFrequencyStepUpInput, setSelectedSipFrequencyStepUpInput] = useState('yearly')


  useEffect(() => {
    if (fundData?.stateValue?.transaction_basket_items[0]?.id && fundData?.stateValue?.transaction_basket_items[0]?.transaction_type === "sip") {
      // console.log(fundData?.stateValue?.transaction_basket_items[0], 'fundData?.stateValue?.transaction_basket_items[0]')
      setHandleStepsOTP(1)

      setAmount(fundData?.stateValue?.transaction_basket_items[0]?.amount)
      SetConsentId(fundData?.stateValue?.transaction_basket_items[0]?.id)
      setStepUpAmount(fundData?.stateValue?.transaction_basket_items[0]?.step_up_amount ? fundData?.stateValue?.transaction_basket_items[0]?.step_up_amount : '')
      setSelectedSipFrequencyInput(fundData?.stateValue?.transaction_basket_items[0]?.frequency)
      if (fundData?.stateValue?.transaction_basket_items[0]?.frequency) {
        setCancellation(true)
      }
      // console.log(fundData?.stateValue?.transaction_basket_items[0])
      setSipDate(fundData?.stateValue?.transaction_basket_items[0]?.installment_day)
      // setSelectedSipFrequencyStepUpInput(fundData?.stateValue?.transaction_basket_items[0]?.step_up_frequency ? fundData?.stateValue?.transaction_basket_items[0]?.step_up_frequency : )
    }
  }, [fundData?.stateValue?.transaction_basket_items[0]?.id, kycData, kycStatus]);


  useEffect(() => {
    setAmount("");
  }, [code]);

  useEffect(() => {
    if (handleStepsOTP == 3) {
      get_all_the_bank_details()
    }
    else {
      if (!mandateList) {
        get_all_mandated_list()
      }
    }

  }, [handleStepsOTP == 1])

  // -------------------------------- get the data of the --------------------------- //
  const get_all_the_bank_details = async () => {
    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${GET_ALL_BANK}`, httpConfig1);
      // console.log(fpres?.data?.data.sip_frequency_data?.MONTHLY, 'fadfadfadsfasdfasdfasdf')
      // console.log(response.data.data)
      setBankList(response?.data?.data);
    } catch { }
  };
  const get_all_mandated_list = async () => {
    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { user_id: localStorage.getItem("user_id") }
    };

    try {
      const response = await httpInstance(`${MANDATES_DETAILS_OF_BANK}`, httpConfig1);
      // console.log(fpres?.data?.data.sip_frequency_data?.MONTHLY, 'fadfadfadsfasdfasdfasdf')
      // console.log(response.data.data)
      setMandateList(response?.data?.data);
    } catch {

    }
  };




  const selectionOfBank = (selectionOfBank) => {
    setSelectBankError('')
    // selectionOfBank['bankType'] = type
    // // Now you can use the updatedSelection array as needed
    const updatedSelectionOfBank = { ...selectionOfBank };

    // Make changes to the copied object
    // updatedSelectionOfBank['bankType'] = type;
    // Now you can use the updatedSelectionOfBank as needed
    setSelectedBank(updatedSelectionOfBank);
  }
  const formatAccountNumber = (accountNumber) => {
    const length = accountNumber.length;

    // Show only the first 2 and last 2 characters, replace the rest with "x"
    // return accountNumber.substring(0, 2) + '*'.repeat(length - 4) + accountNumber.substring(length - 2);
    return '*'.repeat(length - 4) + accountNumber.substring(length - 4);

  };


  const handle_amount = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "").toLowerCase();
    setAmount(e.target.value);
  };

  const handle_stepup_amount = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "").toLowerCase();
    setStepUpAmount(e.target.value);
  }

  const handle_otp_value = (code) => {
    // e.target.value = e.target.value.replace(/[^0-9]/g, "").toLowerCase();
    // SetOTP(e.target.value);
    SetOTP(code);
  };

  const handle_installments = (value) => {
    setInstallments(value);
  };

  const handle_cancellation = (e) => {
    setCancellation(e.target.checked);
  };

  const handle_sip_no_mandate = (e) => {
    setNoMandateSip(e.target.checked);
  };

  const handle_sipDate = (date) => {
    let sip_date = date < 9 ? `0${date}` : date;
    let month_year = moment().format("YYYY-MM");
    // setSipDate(`${month_year}-${sip_date}`);
    setSipDate(`${sip_date}`);

    //-----------------next sip--------------------
    let today = moment().format("DD");
    let month = moment()
      .add(date > today ? 1 : 2, "months")
      .format("MMM");

    let next_sip = `${number_ordinals(date)} ${month}`;
    setNextSip(next_sip);
  };

  // --------------------------------------- handle confirm sip order after bank selection ------------------------//
  const handle_confirm_SIP_order = async () => {
    if (
      userStore?.user?.id == "" ||
      userStore?.user?.id == "undefined" ||
      userStore?.user?.id == null
    ) {
      navigate(`${namespace}/user`);
    }
    // console.log("kycStatus", kycStatus);
    // console.log(kycData)
    if ((kycData?.status == 'nominee' && kycData?.is_kyc_compliant == true && kycStatus == 'pending') || (!kycData?.is_kyc_compliant && kycStatus == "successful")) {
      // console.log(
      //   "fpData?.minAdditionalInvestment",
      //   fpData?.minAdditionalInvestment
      // );
      // console.log("fpData?.minInitialInvestment", fpData?.minInitialInvestment);
      // console.log("fundData?.folio_number", fundData?.folio_number);
      if (
        amount <
        (fundData?.folio_number
          ? fpData?.min_additional_investment
          : fpData?.min_sip_amount)
      ) {
        setSelectBankError(
          `Minimum Investment is ₹${fundData?.folio_number
            ? fpData?.min_additional_investment
            : fpData?.min_sip_amount
          }`
        );
      }
      else if (
        amount >
        (fundData?.folio_number
          ? fpData?.max_additional_investment
          : fpData?.max_sip_amount)
      ) {
        setSelectBankError(
          `Maximum Investment is ₹${fundData?.folio_number
            ? fpData?.max_additional_investment
            : fpData?.max_sip_amount
          }`
        );
      }
      else if (!selectedBank && !noMandateSip) {
        setSelectBankError("Please select the Bank for SIP")
      } else {
        try {
          setLoading(true)
          setSelectBankError("");
          const formData = {

            user_id: localStorage.getItem("user_id"),
            transaction_basket_items: [
              {
                transaction_type: noMandateSip ? "no_mandate_sip" : "sip",
                fund_isin: fpData?.isin,
                folio_number: fundData?.folio_numbe ? fundData?.folio_numbe : null,
                amount: amount,
                step_up_amount: cancellation ? stepUpAmount : null,
                step_up_frequency: cancellation ? selectedSipFrequencyStepUpInput : null,
                installment_day: sipDate,
                frequency: selectedSipFrequencyInput,
                payment_method: 'mandate',
                payment_source: selectedBank?.mandate_id ? selectedBank?.mandate_id : null,
              }
            ]
          }
          const httpConfig = {
            method: "POST",
            tokenType: "user",
            headers: {
              "Content-Type": "application/json",
            },
            data: formData,
          };

          // console.log(httpConfig)
          var idSet = consentId
          if (!consentId) {
            const response = await httpInstance(
              `${POST_TRANSACTION_BASKETS}`,
              httpConfig
            );
            // console.log(response?.data?.data)
            SetConsentId(response.data?.data.id);

            idSet = response.data.data.id
          }
          const httpConfig1 = {
            method: "POST",
            tokenType: "user",
            headers: {
              "Content-Type": "application/json",
            },
            params: { transaction_basket_id: idSet },
          }
          // console.log(httpConfig1)
          const responseGenerate = await httpInstance(
            `${POST_TRANSACTION_BASKETS_GENERATE_CONSENT}`,
            httpConfig1
          );
          // console.log(responseGenerate, 'responseGenerate')
          // SetConsentId(response.data?.data.id);
          // handle_lumsum_otp(response.data?.data.id);
          SetOTP("");
          setHandleStepsOTP(2);
          CustomToast("OTP sent successfully", "SUCCESS")
          setLoading(false)
          var timeLeft = 30; // set the time limit in seconds
          var timer = setInterval(function () {
            setIsTimerActive(true);
            setotpHasBeenSent(true);
            timeLeft--;
            setTimer(timeLeft);

            if (timeLeft <= 0) {
              clearInterval(timer);
              setotpHasBeenSent(false);
              setIsTimerActive(false);
            }
          }, 1000); // set the interval to 1000 milliseconds (1 second)
        }
        catch (error) {
          setLoading(false)
          setSelectBankError(error.response?.data?.message)
          CustomToast(error?.response?.data?.error ?? error?.response?.data?.message ?? "Somthing went wrong", "FAILED")
        }
      }
    }
  }




  //----------------------submit sip----------------------------------------------------//
  const handle_OTP = async () => {
    // if (sessionStorage.getItem("mpin")) {
    // if (kycStatus == "successful") {
    if ((kycData?.status == 'nominee' && kycData?.is_kyc_compliant == true && kycStatus == 'pending') || (!kycData?.is_kyc_compliant && kycStatus == "successful")) {
      console.log(amount)
      // let min_sip = fpData?.min_sip_amount > 1000 ? fpData?.min_sip_amount : 1000;
      let min_sip = fpData?.min_sip_amount;
      if (amount < min_sip) {
        setApiError(`Minimum SIP Investment is ₹${min_sip}`);
      } else if (!sipDate) {
        // setError("");
        setApiError("Select SIP date");
      } else {
        setError("");
        setSipError("");
        setLoading(true);
        setApiError("");
        //----------------store data--------------------
        const formDataOTP = {
          transaction_basket_id: consentId,
          otp: otp,
        };

        const httpConfigOTP = {
          method: "POST",
          tokenType: "user",
          headers: {
            "Content-Type": "application/json",
          },
          data: formDataOTP,
        };

        const formData = {
          userId: userStore.user?.id,
          transactionTypeId: 2,
          fundPlanId: fundData?.scheme_code,
          fundSubPlanId: null,
          fundFolioNumber: fundData?.folio_number
            ? fundData?.folio_number
            : null,
          fundAmount: amount,
          numberOfInstallments: cancellation ? 1200 : installments,
          frequency: "MONTHLY",
          isin: fundData?.isin,
          startDate: sipDate,
          nav: fundData?.nav,
          isWeb: true,
          amcId: fundData?.amc_code,
          planName: fundData?.scheme_name,
          mfSipConsentId: consentId,
        };

        const httpConfig = {
          method: "POST",
          tokenType: "user",
          headers: {
            "Content-Type": "application/json",
          },
          data: formData,
        };

        try {
          const responseOTP = await httpInstance(
            `${VERIFY_AND_VALIDATE_BASKET_CONSENT_SIP_OTP}`,
            httpConfigOTP
          );
          // console.log(responseOTP)
          // console.log({
          //       orderData: responseOTP.data?.data,
          //       type: "sip",
          //       schemeCode: fundData?.id,
          //       fundData: fundData,
          //       consentId: consentId,
          //     })
          if (responseOTP?.data?.data?.transaction_basket_items?.[0]?.status === 'failed') {
            setLoading(false);
            setApiError(responseOTP?.data?.data?.transaction_basket_items?.[0]?.response_message);
            setTimeout(() => {
              setHandleStepsOTP(0);
              setApiError("");
            }, 3000)
            // clearInterval(timer);
            // setotpHasBeenSent(false);
            // setIsTimerActive(false);

          } else {

            navigate(`${namespace}/mutualfund/order-summary`, {
              state: {
                orderData: responseOTP.data?.data,
                type: "sip",
                schemeCode: fundData?.id,
                fundData: fundData,
                consentId: consentId,
              },
            });
          }
          // if (responseOTP["data"]["responseStatus"] == "Success") {
          //   const response = await httpInstance(`${CREATE_SIP}`, httpConfig);
          //   sessionStorage.setItem(
          //     "sip",
          //     JSON.stringify({
          //       name: fundData?.scheme_name,
          //       img: fundData?.img_url,
          //     })
          //   );
          //   navigate(`${namespace}/mutualfund/order-summary`, {
          //     state: {
          //       orderData: response.data?.data,
          //       type: "sip",
          //       schemeCode: fundData?.scheme_code,
          //     },
          //   });
          //   setHandleStepsOTP(0);
          //   //window.location.replace(response.data?.data?.tokenUrl);
          // } else {
          //   setApiError(responseOTP.message?.message);
          //   setHandleStepsOTP(0);
          // }
        } catch (error) {
          if (
            error.response.data?.message.includes(
              `Mandate Account not created`
            )
          ) {
            setMandate(true);
          } else {
            setApiError(error.response.data?.message);
          }

          setLoading(false);
        }
      }
    } else {
      show_banner();
      setApiError(
        kycStatus == "submitted"
          ? "Your KYC is under review"
          : "Please complete your KYC"
      );
    }
    // } else {
    //   navigate(`${namespace}/user`);
    // }
  };

  const handle_SIP = async () => {
    if (
      userStore?.user?.id == "" ||
      userStore?.user?.id == "undefined" ||
      userStore?.user?.id == null
    ) {
      navigate(`${namespace}/user`);
    }
    if ((kycData?.status == 'nominee' && kycData?.is_kyc_compliant == true && kycStatus == 'pending') || (!kycData?.is_kyc_compliant && kycStatus == "successful")) {
      setSelectBankError("")
      // console.log(fundData?.folio_number, 'fundData?.folio_number', fpData?.min_additional_investment, 'fpData?.min_additional_investment', fpData?.min_initial_investment, 'fpData?.min_initial_investment')
      if (
        amount <
        (fundData?.folio_number
          ? fpData?.min_additional_investment
          : fpData?.min_sip_amount)
      ) {
        setError(
          `Minimum Investment is ₹${fundData?.folio_number
            ? fpData?.min_additional_investment
            : fpData?.min_sip_amount
          }`
        );
      } else if (
        amount >
        (fundData?.folio_number
          ? fpData?.max_additional_investment
          : fpData?.max_sip_amount)) {
        setError(
          `Maximum Investment is ₹${fundData?.folio_number
            ? fpData?.max_additional_investment
            : fpData?.max_sip_amount
          }`
        );

      } else if (!sipDate) {
        setError("");
        setSipError("Select SIP date");
      } else if (cancellation && !stepUpAmount) {
        setError("");
        setSipError("");
        setStepUpAmountError("Please enter step up amount");
      } else {
        setError("");
        setSipError("");
        setStepUpAmountError("");
        if (noMandateSip) {
          // setHandleStepsOTP(2)
          handle_confirm_SIP_order()
        } else {
          setHandleStepsOTP(1);
        }

        // const httpConfig = {
        //   method: "GET",
        //   tokenType: "user",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // };

        // const response = await httpInstance(`${GET_CONSENT_SIP_OTP}`, httpConfig);
        // SetOTP("");
        // SetConsentId(response.data?.data.id);

        // var timeLeft = 30; // set the time limit in seconds
        // var timer = setInterval(function () {
        //   setIsTimerActive(true);
        //   setotpHasBeenSent(true);
        //   timeLeft--;
        //   setTimer(timeLeft);

        //   if (timeLeft <= 0) {
        //     clearInterval(timer);
        //     setotpHasBeenSent(false);
        //     setIsTimerActive(false);
        //   }
        // }, 1000); // set the interval to 1000 milliseconds (1 second)
      }
    }
    else {
      setSelectBankError("Complete Your KYC");

    }
  };

  // useEffect(() => {
  //   let interval;
  //   if (isTimerActive && timer > 0) {
  //     interval = setInterval(() => {
  //       setTimer((prev) => prev - 1);
  //     }, 1000);
  //   } else if (timer === 0) {
  //     setIsTimerActive(false);
  //     setotpHasBeenSent(true);
  //   }
  //   return () => clearInterval(interval);
  // }, [isTimerActive, timer]);




  const handleDropdownSipFrequency = (event) => {
    setSelectedSipFrequencyInput(event.target.value);
  };

  const handleDropDownStepupFrequesncy = (event) => {
    setSelectedSipFrequencyStepUpInput(event.target.value)
  }

  // back button redirecting back from the e mandate page 
  const handleStepsBackOtp = () => {
    handleStepsOTP(2)
  }


  return (
    <div>

      {handleStepsOTP == 1 && (
        <Box mx={5}>
          <Box>
            <p className={classes.mainText}>
              Select Bank Account
            </p>
          </Box>
          {/* {console.log(mandateList, 'asdfasdf')} */}
          <Box style={{overflowY: 'auto'}} height={'40vh'}>
          {mandateList?.map((list, i) => {
            return (
              <>
                {(list?.status === 'success' || list?.status === 'submitted' || list?.status === 'approved') &&
                  <Box
                    item xs={2} md={1}
                    display="flex"
                    key={i}
                    style={{
                      border: (selectedBank?.id === list?.id) ? '1px solid #0E76BC' : '1px solid #cccc',
                      cursor: 'pointer',
                      marginBottom: '10px', // Add margin to create spacing between items
                      position: 'relative',
                      padding: '10px', // Add padding for better aesthetics
                      borderRadius: '12px',
                    }}
                    p={1}
                    onClick={() => {
                      selectionOfBank(list)
                    }}
                  >

                    <Box width='100%' paddingLeft='10px' display='flex' justifyContent='center' alignItems={"center"} flexDirection={'column'}>
                      <img src={`${BASE_URL}${list?.logo_url}`} padding="2px 10px" height="50px" width='auto' />

                      <Typography className={classes.headers} style={{ textTransform: 'capitalize' }}>
                        {list?.user_bank_detail?.bank_name}
                      </Typography>
                      <Box display={'flex'} justifyContent={'space-between'}>
                        <Typography className={classes.subText}>
                          Digital AutoPay {list?.status}
                        </Typography>
                        <Typography className={classes.subText}>
                          Mandate ID: {list?.mandate_id}
                        </Typography>
                      </Box>
                      <Box textAlign="center" display={'flex'} justifyContent={'space-between'}>
                        <Typography className={classes.text}>
                          Account Number {formatAccountNumber(list?.user_bank_detail?.account_number)}
                        </Typography>
                      </Box>
                      <Box textAlign="center">
                        <Typography className={classes.text}>
                          Max limit   <span className={classes.rupees}>₹</span>
                          {list?.mandate_limit}
                        </Typography>
                      </Box>

                    </Box>

                    <Box width={17} marginRight='10px' display='flex' justifyContent='center' alignItems={"center"} >
                      {(selectedBank?.id === list?.id) && (
                        // <img
                        //   src="/images/checkMark.svg"
                        //   height="16"
                        //   width="16"
                        //   className={classes.closeBtn}
                        // />
                        <CheckCircleIcon style={{ fontSize: '20px', color: '#0E76BC' }} className={classes.closeBtn} />
                      )}
                    </Box>

                  </Box>
                }
              </>
            );
          })}
          </Box>
          <Box display={'flex'} justifyContent={'center'} my={3}>
            <button className={classes.secondaryButton} onClick={() => setHandleStepsOTP(3)} disabled={!fpData}>
              Add new Auto pay
            </button>
          </Box>
          <div>{selectBankError && <ErrorMessage message={selectBankError} />}</div>
          <Box display={'flex'} justifyContent={'center'} my={3}>
            <button className={classes.primaryButton} onClick={handle_confirm_SIP_order} disabled={!fpData || loading}>
              {loading ? (
                <CircularProgress size="1rem" color="inherit" />
              ) : (
                "Confirm SIP Order"
              )}
            </button>
          </Box>
        </Box>
      )}
      {handleStepsOTP == 2 && (
        < Box m={3} p={3}>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} my={0}>
            <img src="/images/otpScreen.svg" alt="otp" height={100} width={100} />
            <Typography variant="h6">Confirm your order with OTP</Typography>
          </Box>
          <label className={classes.label}>Enter OTP</label>
          {/* <input
            className={classes.inputBox}
            type="text"
            placeholder="Enter One Time Password"
            onChange={handle_otp_value}
            value={otp}
          /> */}

          <OTPInput
            value={otp}
            onChange={handle_otp_value}
            numInputs={4}
            renderSeparator={<span style={{ width: "8px" }}></span>}
            isInputNum={true}
            shouldAutoFocus={true}
            renderInput={(props) => <input {...props} />}
            // inputStyle={{
            //   border: "0px 0px 0px 1px",
            //   // borderRadius: "8px", 
            //   borderRadius: "0px",
            //   borderBottom: "1px solid #000 !important",
            //   width: "40px",
            //   height: "40px",
            //   fontSize: "13px",
            //   color: "#000",
            //   fontWeight: "400",
            //   caretColor: "blue",
            //   background: "#E6E6E6",
            // }}
            // focusStyle={{
            //   // border: "1px solid #CFD3DB",
            //   borderBottom: "1px solid #000",
            //   background: "#E6E6E6",
            //   outline: "none",
            // }}
            inputStyle={{
              border: "none",
              borderRadius: "0px",
              borderBottom: "1px solid #000",
              width: "40px",
              height: "40px",
              fontSize: "13px",
              color: "#000",
              fontWeight: "400",
              caretColor: "blue",
            }}
            focusStyle={{
              border: "none",
              borderRadius: "0px",
              borderBottom: "1px solid #000", // Ensure this matches inputStyle
              background: "#E6E6E6",
              outline: "none",
            }}
          />
          <div className="resendOTP">
            {otpHasBeenSent ? (
              <></>
            ) : (
              <>
                <p className="otpWillRecivie">
                  OTP has been sent you will receive shortly
                </p>
              </>
            )}
            {isTimerActive ? (
              <div>
                <p className="resendOTPTimer">Resend OTP in {timer} seconds</p>
              </div>
            ) : (
              <div>
                <p className="resendOTPRequest">
                  Didn't receive OTP?{" "}
                  {/* <a href="javascript:void" onClick={handle_confirm_SIP_order}>
                    Resend OTP
                  </a> */}
                   <p
                    onClick={handle_confirm_SIP_order}
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                      display: "inline", // Keeps the text inline with "Didn't receive OTP?"
                    }}
                  >
                    Resend OTP
                  </p>
                </p>
              </div>
            )}
          </div>

          {apiError && <ErrorMessage message={apiError} />}
          <button
            className={classes.primaryButton}
            onClick={handle_OTP}
            style={{ marginTop: "10px" }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size="1rem" color="inherit" />
            ) : (
              "Verify OTP"
            )}
          </button>
          <div>{error && <ErrorMessage message={error} />}</div>
        </Box>
      )}
      {handleStepsOTP == 3 && (
        <EMandate
          dialog={mandate}
          closeDialog={() => (
            setMandate(false),
            setHandleStepsOTP(1)
          )}
          bankList={bankList}

        />

      )}
      {handleStepsOTP == 0 && (
        <>
          {fundData?.folio_numb ?
            < Box p={3} >
              <label className={classes.label}>Enter Amount</label>
              <span className={classes.rupeesSymbol} style={{ marginRight: '5px' }}>₹</span>

              <input
                className={classes.inputBox}
                type="text"
                placeholder="Enter Amount"
                onChange={handle_amount}
                value={amount}
              />
              {/* <Typography className={classes.textgrey}>
            Note: Your first SIP on today
          </Typography> */}
              {error && <ErrorMessage message={error} />}
              <div>{selectBankError && <ErrorMessage message={selectBankError} />}</div>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                mt={10}
              >
                <button className={classes.primaryButton} onClick={handle_confirm_SIP_order} disabled={!fpData}>
                  {loading ? (
                    <CircularProgress size="1rem" color="inherit" />
                  ) : (
                    "Start SIP"
                  )}
                </button>
              </Box>
            </Box>
            :
            < Box p={3} >
              <label className={classes.label}>Enter Amount</label>
              <span className={classes.rupeesSymbol} style={{ marginRight: '5px' }}>₹</span>

              <input
                className={classes.inputBox}
                type="text"
                placeholder="Enter Amount"
                onChange={handle_amount}
                value={amount}
              />
              {/* <Typography className={classes.textgrey}>
            Note: Your first SIP on today
          </Typography> */}
              {error && <ErrorMessage message={error} />}
              <Box
                my={2}
                //display="flex"
                alignItems={"flex-start"}
                justifyContent="flex-start"
                flexDirection="column"
                width={"100%"}
              >
                <label className={classes.label}>SIP Frequency</label>
                <Box width={"100%"} className={classes.Select}>
                  <Select
                    fullWidth
                    value={selectedSipFrequencyInput}
                    onChange={handleDropdownSipFrequency}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    disableUnderline
                    className={classes.selectItem}
                    IconComponent={KeyboardArrowDownIcon}
                    MenuProps={{
                      style: {
                        top: 5,
                        minWidth: 200,
                        width: '100%',
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {/* {relation &&
                    relation?.map((ele) => {
                      return (
                        <MenuItem
                          key={ele.id}
                          className={classes.menuItem}
                          value={ele.type}
                        >
                          {ele.type}
                        </MenuItem>
                      );
                    })} */}
                    {fpData?.sip_frequency_specific_data?.monthly &&
                      <MenuItem value="monthly" className={classes.menuItem}>Monthly</MenuItem>
                    }
                    {/* {fpData?.sip_frequency_specific_data?.daily &&

                      <MenuItem value="daily" className={classes.menuItem}>Daily</MenuItem>
                    }
                    {fpData?.sip_frequency_specific_data?.day_in_a_week &&

                      <MenuItem value="day_in_a_week" className={classes.menuItem}>Day in a week</MenuItem>
                    }
                    {fpData?.sip_frequency_specific_data?.quarterly &&

                      <MenuItem value="quarterly" className={classes.menuItem}>Quartely</MenuItem>
                    } */}
                  </Select>
                </Box>

              </Box>
              {/* {fpData?.sip_frequency_data?.MONTHLY, MONTHLY} */}
              <SipDatePicker
                label="SIP Date"
                placeholder="Select SIP Date"
                dates={fpData?.sip_frequency_data?.MONTHLY ? fpData?.sip_frequency_data?.MONTHLY : ""}
                handle_sipDate={handle_sipDate}
              />
              {siperror && <ErrorMessage message={siperror} />}


              <Box className={classes.checkBox} mt={2}>
                {/* <FormControlLabel
              control={
                <Switch
                  className={classes.switchButton}
                  checked={cancellation}
                  onChange={handle_cancellation}
                  color="primary"
                  size="large"
                // col
                />
              }
              label="Step Up"
            /> */}
                <FormControlLabel
                  style={{ marginLeft: 0 }}
                  control={
                    <IOSSwitch
                      checked={cancellation}
                      onChange={handle_cancellation}
                    />
                  }
                  label="Step Up"
                  labelPlacement="end"
                />
              </Box>
              {
                cancellation && (
                  <>
                    {/* <label className={classes.label}>Number of Installments</label>
              <Box className={classes.root} mt={5} px={2}>
                <Slider
                  classes={{
                    thumb: classes.thumb,
                    rail: classes.rail,
                    track: classes.track,
                    valueLabel: classes.valueLabel,
                    mark: classes.mark,
                  }}
                  defaultValue={60}
                  aria-label="Default"
                  valueLabelDisplay="on"
                  size="medium"
                  valueLabelFormat={(x) => {
                    return (
                      <p style={{ color: "black", fontSize: "0.7rem" }}>
                        {x}months
                      </p>
                    );
                  }}
                  min={fpData?.minSipInstallments}
                  max={120}
                  onChange={(e, value) => handle_installments(value)}
                />
              </Box> */}
                    <label className={classes.label}>Set Up Amount</label>
                    <input
                      className={classes.inputBox}
                      type="text"
                      placeholder="Enter step up amount"
                      onChange={handle_stepup_amount}
                      value={stepUpAmount}
                    />
                    {stepUpAmountError && (
                      <ErrorMessage message={stepUpAmountError} />
                    )}
                    <Box
                      my={2}
                      //display="flex"
                      alignItems={"flex-start"}
                      justifyContent="flex-start"
                      flexDirection="column"
                      width={"100%"}
                    >
                      <label className={classes.label}>SIP Frequency</label>
                      <Box width={"100%"} className={classes.Select}>
                        <Select
                          fullWidth
                          value={selectedSipFrequencyStepUpInput}
                          onChange={handleDropDownStepupFrequesncy}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          disableUnderline
                          className={classes.selectItem}
                          IconComponent={KeyboardArrowDownIcon}
                          MenuProps={{
                            style: {
                              top: 5,
                              minWidth: 200,
                              width: '100%',
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          {/* {fpData?.sip_frequency_specific_data?.monthly && */}
                          <MenuItem value="yearly" className={classes.menuItem}>Yearly</MenuItem>
                          {/*  } */}
                          {/* {fpData?.sip_frequency_specific_data?.daily &&

                      <MenuItem value="daily" className={classes.menuItem}>Daily</MenuItem>
                    }
                    {fpData?.sip_frequency_specific_data?.day_in_a_week &&

                      <MenuItem value="day_in_a_week" className={classes.menuItem}>Day in a week</MenuItem>
                    }
                    {fpData?.sip_frequency_specific_data?.quarterly &&

                      <MenuItem value="quarterly" className={classes.menuItem}>Quartely</MenuItem>
                    } */}
                        </Select>
                      </Box>
                    </Box>
                  </>
                )
              }
              {/* <Box className={classes.checkBox} mt={2}> */}
              {/* <FormControlLabel
              control={
                <Switch
                  className={classes.switchButton}
                  checked={noMandateSip}
                  onChange={handle_sip_no_mandate}
                  color="primary"
                  size="large"
                // col
                />
              }
              label="No Mandate SIP"
            /> */}
              {/* <FormControlLabel
              style={{ marginLeft: 0 }}
              control={
                <IOSSwitch
                  // sx={{ m: 3 }}
                  // defaultChecked 
                  // onChange={handle_whatsApp}
                  //  checked={whatsAppnotification} 
                  // className={classes.switchButton}
                  checked={noMandateSip}
                  onChange={handle_sip_no_mandate}
                // color="primary"
                // size="large"
                />
              }
              label="No Mandate SIP"
              labelPlacement="end"
            />

            <Tooltip title={<span>What is No Mandate SIP? <br /> No Bank Mandate SIP is a type of Systematica Investment Plan (SIP) in mutual funds where the investor does not have to provide a mandate to their bank for automatic deduction of the investment amount from their bank account. We will send you a notification on your SIP date with the payment link for your investment.</span>} arrow placement="top-start">
              <img src="/images/exclamation-mark.svg" alt="icon" width="12px" height='12px' />
            </Tooltip>
          </Box> */}

              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                mt={10}
              >
                <div>{selectBankError && <ErrorMessage message={selectBankError} />}</div>
                {nextSip && (
                  <Typography className={classes.textblue}>
                    {`Your next SIP on ${nextSip}`}{" "}
                  </Typography>
                )}
                {apiError && <ErrorMessage message={apiError} />}
                <button className={classes.primaryButton} onClick={handle_SIP} disabled={!fpData}>
                  {loading ? (
                    <CircularProgress size="1rem" color="inherit" />
                  ) : (
                    "Start SIP"
                  )}
                </button>
              </Box>
            </Box >
          }
        </>
      )
      }


      {/* <MandateAlert dialog={mandate} closeDialog={() => setMandate(false)} /> */}

    </div >
  );
};

export default Sip;
