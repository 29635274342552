import React, { useState, useEffect } from "react";
import { Box, makeStyles, Container, p, Grid, Button } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import {
  KYC_DATA,
  KYC_STATUS,
  SET_KYC_POPUP,
  GET_USER_ONBOARDING_DETAILS,
  POST_CREATING_INVESTER,
  POST_CREATE_INVESTMENT_ACCOUNT,
} from "../../../../utils/endpoints";
import httpInstance from "../../../../utils/instance";
import RejectedReason from "./RejectedReason";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { namespace } from "../../../../environment";

function Kyc_status() {
  const useStyles = makeStyles({
    root: {
      background: "#CAE8FC",
      backgroundImage: "url(/images/endless-constellation.svg)",
      backgroundSize: 'no-repeat',
      display: "flex",
      borderRadius: "10px",
      overflow: 'hidden',
      border: '1px solid #CAE8FC',
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
      marginTop: "1rem",
      "& p": {
        margin: "4px",
      },
    },
    mainPart: {
      padding: "0 60px",
    },
    backdropFilterPart: {
      width: "100%",
      backdropFilter: "blur(4px)",
    },
    title: {
      fontWeight: "bold",
      padding: "10px 0px",
    },

    text: {
      fontSize: "0.8rem",
    },
    image: {
      height: "200px",
      width: "200px",
    },

    primaryButton: {
      padding: "0.7rem 7rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      cursor: "pointer",
      marginTop: "10px",
      transition: '0.5s ease',
      border: "1px solid #0E76BC",
      "&:hover": {
        background: "#fff",
        color: "#0E76BC",
      },
    },
    "@media (max-width: 600px)": {
      mainPart: {
        padding: "0 20px",
      },
      primaryButton: {
        padding: "0.7rem 4rem",

      },
      image: {
        height: "100px",
        width: "100px",
      },
    },
  });

  const classes = useStyles();

  const [kycStatus, setKycStatus] = useState("submitted");
  const [showModal, setShowModal] = useState(false);
  const [isComplient, setIsComplient] = useState(false);
  const [statusData, setStatusData] = useState(null)
  const [isOnboarding, setIsOnBoarding] = useState(false);
  const [isInvestorAccCreateDone, setisInvestorAccCreateDone] = useState(false);
  const [showBanner, setShowBanner] = useState(
    localStorage.getItem("banner") ? false : true
  );
  const [loading, setLoading] = useState(true);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const navigate = useNavigate();
  const { userStore, userKYCStore } = useSelector((state) => state);

  //---------------------last routed screens-----------------------------------------------------------//
  const lastScreen = {
    "Check Pan Status": 4,
    "Confirm Pan": 19,
    "Pan Upload": 6,
    "Personal Details": 7,
    "Fatca Details": 8,
    "Address Details": isComplient ? 9 : 20,
    "Address Proof Uploded": 9,
    "Nominee Details": 10,
    "Cancel Cheque": 11,
    "Aadhar Number Uploaded": 13,
    "Bank Details": userStore.user?.onboardingBankVerified ? 13 : 12,
    "Signature Upload": 21,
    "Profile Pic Upload": 22,
    "User Video Uploded": 23,
    "E Sign": 14,
  };

  const get_kyc_data = async () => {
    // const httpConfig = {
    //   method: "GET",
    //   tokenType: "user",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // };

    // try {
    //   // const response = await httpInstance(`${GET_LAST_ROUTED_SCREEN}`, httpConfig)
    //   // // const response = await httpInstance(`${KYC_DATA}`, httpConfig);
    //   // // console.log(response.data.data);
    //   // const checking = await httpInstance(`${GET_USER_ONBOARDING_DETAILS}`, httpConfig);
    //   // // console.log(checking)
    //   // // localStorage.setItem("kycStatus", response.data?.data?.fp_kyc_status);
    //   const status = await httpInstance(`${KYC_STATUS}`, httpConfig);
    //   setKycStatus(status?.data?.data?.fp_kyc_status);
    //   setIsComplient(status?.data?.data?.is_kyc_compliant);
    //   setStatusData(status?.data?.data)
    //   setIsOnBoarding(status?.data?.data?.isOnBoardingCompleted);
    //   setisInvestorAccCreateDone(status?.data?.data?.isInvestorAccCreateDone);
    //   setLoading(false);
    // } catch {
    //   setKycStatus("empty");
    //   setLoading(false);
    // }

    const status = userKYCStore?.userKyc
    setKycStatus(status?.fp_kyc_status);
    setIsComplient(status?.is_kyc_compliant);
    setStatusData(status)
    setIsOnBoarding(status?.isOnBoardingCompleted);
    setisInvestorAccCreateDone(status?.isInvestorAccCreateDone);
    setLoading(userKYCStore.loading);
  };

  // console.log(userKYCStore, 'sdfsdfsd')


  const creatingTheInvestmentAccount = async () => {
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(
        `${POST_CREATE_INVESTMENT_ACCOUNT}`,
        httpConfig
      );
      console.log(' create invester')
    } catch { }
  }

  const creatingTheInvesterAccount = async () => {
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(
        `${POST_CREATING_INVESTER}`,
        httpConfig
      );
      console.log('create invester')
      creatingTheInvestmentAccount()
    } catch (error) {
      if (error.response.data?.status == 400) {
        creatingTheInvestmentAccount()

      }

    }
  }


  const creatingTheInvesterAccountAndInvestmentAccount = async () => {
    if (statusData?.is_kyc_compliant && statusData?.status == 'nominee' && (statusData?.fp_investor_id == null || statusData?.fp_investment_account_id == null)) {
      creatingTheInvesterAccount()
      // console.log('adfadfa')
    }
    else if (!statusData?.is_kyc_compliant && statusData?.fp_esign_status == 'successful' && (statusData?.fp_investor_id == null || statusData?.fp_investment_account_id == null)) {
      creatingTheInvesterAccount()
    }
  }
  useEffect(() => {
    if (statusData) {
      creatingTheInvesterAccountAndInvestmentAccount();
    }
  }, [statusData]);


  useEffect(() => {
    get_kyc_data();
    setShowBanner(userStore?.user?.kycPopup);
  }, [userStore, userKYCStore, userKYCStore?.userKyc]);

  const complete_kyc = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(
        `${KYC_STATUS}`,
        httpConfig
      );

      // console.log(response.data?.data);
      if (response.data.data.status === 'not_started') {
        navigate({
          pathname: `${namespace}/onBoarding`,
          search: `?${createSearchParams({ stepcount: "1" })}`,
        });
      }
      // KYC Compliant
      else if (response.data.data.is_kyc_compliant) {
        // console.log(response.data.data.is_kyc_compliant)
        if (response.data.data.status === 'kyc_check') {
          navigate({
            pathname: `${namespace}/onBoarding`,
            search: `?${createSearchParams({ stepcount: "2" })}`,
          });
        }
        else if (response.data.data.status === "confirm_pan") {
          navigate({
            pathname: `${namespace}/onBoarding`,
            search: `?${createSearchParams({ stepcount: "3" })}`,
          });
        }
        // else if (Kyc_status.data.data.status === "confirm_pan") {
        //   navigate({
        //     pathname: `${namespace}/onBoarding`,
        //     search: `?${createSearchParams({ stepcount: "6" })}`,
        //   });
        // }
        else if (response.data.data.status === "personal_details") {
          navigate({
            pathname: `${namespace}/onBoarding`,
            search: `?${createSearchParams({ stepcount: "4" })}`,
          });
        }
        else if (response.data.data.status === "occupation_details") {
          navigate({
            pathname: `${namespace}/onBoarding`,
            search: `?${createSearchParams({ stepcount: "5" })}`,
          });
        }
        else if (response.data.data.status === "bank_details") {
          navigate({
            pathname: `${namespace}/onBoarding`,
            search: `?${createSearchParams({ stepcount: "6" })}`,
          });
        }
        else if (response.data.data.status === "address_details") {
          navigate({
            pathname: `${namespace}/onBoarding`,
            search: `?${createSearchParams({ stepcount: "7" })}`,
          });
        }
        else if (response.data.data.status === "signature") {
          navigate({
            pathname: `${namespace}/onBoarding`,
            search: `?${createSearchParams({ stepcount: "8" })}`,
          });
        }
        // else if (response.data.data.status === "video") {
        //   navigate({
        //     pathname: `${namespace}/onBoarding`,
        //     search: `?${createSearchParams({ stepcount: "5" })}`,
        //   });
        // }

      }
      // KYC Non Compliant
      else if (!response.data.data.is_kyc_compliant) {
        if (response.data.data.status === 'kyc_check') {
          navigate({
            pathname: `${namespace}/onBoarding`,
            search: `?${createSearchParams({ stepcount: "9" })}`,
          });
        }
        else if (response.data.data.status === 'confirm_pan') {
          navigate({
            pathname: `${namespace}/onBoarding`,
            search: `?${createSearchParams({ stepcount: "10" })}`,
          });
        }
        else if (response.data.data.status === 'personal_details') {
          navigate({
            pathname: `${namespace}/onBoarding`,
            search: `?${createSearchParams({ stepcount: "11" })}`,
          });
        }
        else if (response.data.data.status === 'occupation_details') {
          navigate({
            pathname: `${namespace}/onBoarding`,
            search: `?${createSearchParams({ stepcount: "12" })}`,
          });
        }
        else if (response.data.data.status === 'bank_details') {
          navigate({
            pathname: `${namespace}/onBoarding`,
            search: `?${createSearchParams({ stepcount: "13" })}`,
          });
        }
        else if (response.data.data.status === "nominee") {
          navigate({
            pathname: `${namespace}/onBoarding`,
            search: `?${createSearchParams({ stepcount: "14" })}`,
          });
        }
        else if (response.data.data.status === "proof_of_identity" || response.data.data.status === "geo_tag") {
          navigate({
            pathname: `${namespace}/onBoarding`,
            search: `?${createSearchParams({ stepcount: "15" })}`,
          });
        }
        else if (response.data.data.status === "proof_of_identity" || response.data.data.status === "geo_tag") {
          navigate({
            pathname: `${namespace}/onBoarding`,
            search: `?${createSearchParams({ stepcount: "16" })}`,
          });
        }
        else if (response.data.data.status === "address_details") {
          navigate({
            pathname: `${namespace}/onBoarding`,
            search: `?${createSearchParams({ stepcount: "17" })}`,
          });
        }
        else if (response.data.data.status === "signature") {
          navigate({
            pathname: `${namespace}/onBoarding`,
            search: `?${createSearchParams({ stepcount: "18" })}`,
          });
        }
        // else if (response.data.data.status === "initiate_esign") {
        //   navigate({
        //     pathname: `${namespace}/onBoarding`,
        //     search: `?${createSearchParams({ stepcount: "18" })}`,
        //   });
        // }
      }
      // if (response.data?.data?.lastRoutedScreen == null) {
      //   navigate({
      //     pathname: `${namespace}/user`,
      //     search: `?${createSearchParams({ stepcount: `2` })}`,
      //   });
      // } else {
      //   navigate({
      //     pathname: `${namespace}/onBoarding`,
      //     search: `?${createSearchParams({
      //       stepcount: `${lastScreen[response.data?.data?.lastRoutedScreen]}`,
      //     })}`,
      //   });
      // }
    } catch { }
  };

  const handle_hide_banner = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${SET_KYC_POPUP}`, httpConfig);
      setShowBanner(true);
    } catch { }

    // localStorage.setItem("banner", true);
  };

  return (
    <Box className={classes.mainPart}>
      {!loading && (
        <>
          {isComplient === true &&
            isInvestorAccCreateDone === true &&
            isOnboarding === false ? (
            <Box className={classes.root} mb={3}>
              <Box className={classes.backdropFilterPart} >
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <Box
                      px={3}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/images/Group 23150.svg"
                        className={classes.image}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={5}>
                    <Box py={2}>
                      <p className={classes.title}>Complete your KYC</p>
                      <p className={classes.text}>
                        Error creating your Investment Account Please contact
                        Support{" "}
                      </p>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          ) : (
            (
              kycStatus == "submitted"
              //  ||
              //  kycStatus == "successful"
            ) &&
            !isOnboarding && (
              <Box className={classes.root} mb={3}>
                <Box className={classes.backdropFilterPart} >
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <Box p={3} display='flex' justifyContent='center'>
                        <img
                          src="/images/undraw_process_re_gws7 1.svg"
                          className={classes.image}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={5} style={{ display: "flex", alignItems: "center" }}>
                      <Box py={2} >
                        <p className={classes.title}>Your KYC is under review</p>
                        {/* <p className={classes.text}>Hey, </p> */}
                        <p className={classes.text}>
                          {/* Congrats on successfully completing your KYC onboarding.
                      We are currently reviewing your application & well get
                      back with an update in next 2 - 3 days */}
                          Please contact customer service if you have any concerns or questionsduring the review process.
                        </p>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )
          )}
          {((!statusData?.is_kyc_compliant &&
            (kycStatus?.status == null ||
              kycStatus?.status == "empty" ||
              statusData?.status == 'pending') &&
            (!statusData?.fp_investor_id ||
              !statusData?.fp_investment_account_id) &&
            statusData.status !== 'initiate_esign'))}
          {/* {console.log(kycStatus, statusData,  (kycStatus == null ||
                kycStatus == "empty" ||
                kycStatus == 'pending'))}
          {console.log((!statusData?.is_kyc_compliant &&
            (kycStatus?.status == null ||
              kycStatus?.status == "empty" ||
              statusData?.status == 'pending') &&
            !statusData?.fp_investor_id &&
            !statusData?.fp_investment_account_id &&
            statusData.status !== 'initiate_esign'),(!statusData?.fp_investor_id ||
              !statusData?.fp_investment_account_id ))} */}
          {/* {console.log((kycStatus == null || kycStatus == "empty" || kycStatus == 'pending' && statusData?.is_kyc_compliant == false && statusData.status !== 'nominee'))} */}
          {/* {(kycStatus == null || kycStatus == "empty" || kycStatus == 'pending' && statusData?.is_kyc_compliant == false ) && (kycStatus == null || kycStatus == "empty" || kycStatus == 'pending' && statusData?.is_kyc_compliant == true && statusData.status !== 'nominee' ) ( */}
          {/* {((kycStatus == null || kycStatus == "empty" || kycStatus == 'pending' && !statusData?.fp_investor_id && !statusData?.fp_investment_account_id && !statusData?.is_kyc_compliant && statusData.status !== 'nominee') || (kycStatus == null || kycStatus == "empty" || kycStatus == 'pending' && statusData?.is_kyc_compliant && statusData.status !== 'nominee')) && ( */}
          {/* {((statusData?.status  == null || statusData?.status  == "empty" || statusData?.status == 'pending' && !statusData?.fp_investor_id && !statusData?.fp_investment_account_id && !statusData?.is_kyc_compliant && statusData.status !== 'nominee') || (kycStatus == null || kycStatus == "empty" || kycStatus == 'pending' && statusData?.is_kyc_compliant && statusData.status !== 'nominee')) && ( */}
          {/* {(kycStatus?.status == null || kycStatus?.status == "empty" || statusData?.status == 'pending' && !statusData?.fp_investor_id && !statusData?.fp_investment_account_id && !statusData?.is_kyc_compliant && statusData.status !== 'submitted') && ( */}


          {((!statusData?.is_kyc_compliant &&
            (kycStatus?.status == null ||
              kycStatus?.status == "empty" ||
              statusData?.status == 'pending') &&
            (!statusData?.fp_investor_id ||
              !statusData?.fp_investment_account_id) &&
            statusData.status !== 'initiate_esign'
          )
            ||
            (statusData?.is_kyc_compliant &&
              (kycStatus == null ||
                kycStatus == "empty" ||
                kycStatus == 'pending') &&
              (!statusData?.fp_investor_id ||
                !statusData?.fp_investment_account_id) &&
              statusData.status !== 'nominee')

          )
            && (
              <Box className={classes.root} mb={3}>
                <Box className={classes.backdropFilterPart} >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={5}>
                      <Box px={3} py={1} display='flex' justifyContent='center'>
                        <img
                          src="/images/Group 23150.svg"
                          className={classes.image}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={7} style={{ display: "flex", alignItems: "center" }}>
                      <Box py={1} px={2}>
                        <p className={classes.title}>Attention Required!</p>
                        {/* <p className={classes.text}>
                      Hey, you have not completed your KYC,{" "}
                    </p> */}
                        <p className={classes.text}>
                          Set up your mutual fund account to bigin investing
                        </p>
                        {/* <p className={classes.text}>
                      Click below to continue from where you left off.
                    </p> */}
                        <Box display="grid" placeSelf="center">
                          <button
                            className={classes.primaryButton}
                            onClick={complete_kyc}
                          >
                            Complete KYC
                          </button>
                        </Box>

                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}


          {kycStatus == "successful" && !showBanner && isOnboarding && (
            <Box className={classes.root} mb={3}>
              <Box className={classes.backdropFilterPart} >
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <Box p={3}>
                      <img
                        src="/images/undraw_certification_re_ifll 1.svg"
                        className={classes.image}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={5}>
                    <Box py={2}>
                      <p className={classes.title}>KYC Verified successfully</p>
                      <p className={classes.text}>Hey, </p>
                      <p className={classes.text}>
                        Congratulations!! Your KYC applications has been
                        successfully verified. Check out our most popular funds
                        below
                      </p>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box display="flex" justifyContent="end">
                      <CloseIcon
                        style={{ cursor: "pointer", padding: "5px" }}
                        onClick={handle_hide_banner}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}

          {kycStatus == "rejected" && (
            <Box className={classes.root} mb={3}>
              <Box className={classes.backdropFilterPart} >
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <Box p={3}>
                      <img
                        src="/images/Group 23171.svg"
                        className={classes.image}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={5}>
                    <Box py={2}>
                      <p className={classes.title}>KYC Rejected</p>
                      <p className={classes.text}>Hey, </p>
                      <p className={classes.text}>
                        Your KYC has been rejected, click below to know what could
                        be the reason for the rejection
                      </p>
                      <button
                        className={classes.primaryButton}
                        onClick={() => {
                          setShowModal(true);
                        }}
                      >
                        Know more
                      </button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
          <RejectedReason
            showModal={showModal}
            handleCloseModal={handleCloseModal}
          />
        </>
      )
      }
    </Box >
  );
}

export default Kyc_status;
