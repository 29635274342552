import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { Container } from "@material-ui/core";
import Funds_filter from "./funds_filter";
import List_funds from "./list_funds";
import { SEARCH_FUNDS, GET_ALL_AMC, SEARCH_FUNDS_BY_NAME, GET_ALL_SPECIFIC_AMC_LIST } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import axios from "axios";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@material-ui/core/IconButton";

function AllFunds({ type }) {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    header: {
      border: "1px solid none",
    },
    connectButton: {
      border: "2px solid #0E76BC",
      color: "#0E76BC",
      borderRadius: "30px",
      padding: "0.6rem 1.8rem",
      fontWeight: 600,
      fontSize: "0.8rem",
      textDecoration: "none",
    },
    links: {
      fontSize: "0.9rem",
      fontWeight: "medium",
    },
    searchBar: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      margin: "0px 0px 20px 0px",
      borderRadius: "10px",
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      width: 100,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
    },
    SearchBarMargin: {
      marginTop: "0rem",
      // marginLeft: "2rem",
    },
    text: {
      textAlign: "center",
    },
    "@media (max-width: 600px)": {
      SearchBarMargin: {
        marginLeft: "0rem",
      },
    },
  }));

  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [allAmc, setAllAmc] = useState([]);
  const [allFunds, setAllFunds] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [aLLAmcIds, setALLAmcIds] = useState([])

  const set_page = () => setPage(page + 1);
  // console.log(page)

  const get_allFunds = async () => {
    // console.log(filterData, 'adfa')

    // let type 
    // if (filterData?.risk_profile){
    //   type = filterData.risk_profile
    // }else if (filterData?.amc_code?.[0]?.amcCode){
    //   type = filterData?.amc_code[0]?.amcCode
    // }else{
    //   type = "high_returns"
    // }


    const typeSelected = await selectedType()
    let finalSelectedType
    if (filterData?.asset_sub_category) {
      finalSelectedType = filterData?.asset_sub_category
    }
    else if (typeSelected?.[0]?.categoryId) {
      finalSelectedType = typeSelected?.[0]?.categoryId
    }
    else {
      finalSelectedType = null
    }
    // console.log(typeSelected, filterData)
    // console.log(finalSelectedType, typeSelected)


    const formData = {
      // from: page == 0 ? 0 : page * 10,
      // size: 10,
      // plan_name: "Broker",
      // option_name: "growth",
      // holding_period: "",
      // asset_category: type == "all" ? "" : type,
      // ratings: [],
      // categoryId: filterData?.amc_code?.[0]?.categoryId ? filterData?.amc_code?.[0]?.categoryId : null,
      categoryId: finalSelectedType ?? typeSelected?.[0]?.categoryId ?? null,
      // categoryId: finalSelectedType,
      // type: filterData?.amc_code?.[0]?.amcCode ? filterData?.amc_code?.[0]?.amcCode : "high_returns",
      type: filterData?.risk_profile ? filterData?.risk_profile : typeSelected?.[0]?.amcCode,

      returnTime: filterData?.amc_code ? filterData?.amc_code : "year1",
      primaryCategoryName: !filterData?.amc_code?.[0]?.categoryId ? null : filterData?.amc_code?.[0]?.categoryId === 137 ? null : "Equity",
      sortBy: "ratings",
      sortOrder: "desc",
      pageNumber: page + 1,
      limit: 10,
      amcIds: aLLAmcIds,
      // categoryId: 18, type: null, returnTime: "year1", primaryCategoryName: "Equity", sortBy: "ratings", sortOrder: "desc", pageNumber: 1, limit: 100
    };
    const httpConfig = {
      method: "POST",
      // tokenType: "user",
      test: 'test',
      // headers: {
      //   "Content-Type": "application/json",
      // },
      // data: { ...formData, ...filterData },
      data: { ...formData },
    };
    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      // test: 'test',
      headers: {
        "Content-Type": "application/json",
      },
    };
    // console.log(httpConfig)

    try {
      // const getSpecificAMC = await httpInstance (`${GET_ALL_SPECIFIC_AMC_LIST}`, httpConfig1)
      // // console.log(getSpecificAMC.data.data, 'getSpecificAMC')
      // const amcIds = getSpecificAMC.data.data.map((item) => item.amcId);

      // console.log(amcIds, 'getSpecificAMC')
      const response = await httpInstance(`${SEARCH_FUNDS}`, httpConfig);
      // const responsenext = await httpInstance (`${GET_ALL_AMC}`)
      // console.log(response.data.data)
      if (response.data?.responseCount == 1) {
        setHasMore(false);
      } else {
        setAllFunds([...allFunds, ...response.data?.data]);
        set_page();
      }
    } catch { }
  };



  const get_allSearchFunds = async () => {
    const formData = {
    };
    const httpConfig = {
      method: "GET",
      // tokenType: "user",
      test: 'test',
      // headers: {
      //   "Content-Type": "application/json",
      // },
      // data: { ...formData, ...filterData },
      // data: { ...formData },
    };

    // console.log(httpConfig)

    try {

      const response = await httpInstance(`${SEARCH_FUNDS_BY_NAME}?name=${filterData.search_text}&amcIds=${aLLAmcIds}`, httpConfig);

      // console.log(response.data.data)
      if (response.data?.responseCount == 0) {
        setHasMore(false);
      } else {
        if (response.data?.responseCount >= page * 10) {
          setAllFunds([...allFunds, ...response.data?.data]);
          set_page();
          // console.log('more is there ')
        } else {
          setAllFunds([...allFunds, ...response.data?.data]);
          set_page();
          setHasMore(false);
          // console.log("no more")
        }

      }
    } catch {
      setHasMore(false);

    }
  }



  const get_all_amc_ids = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      // test: 'test',
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {

      const response = await httpInstance(`${GET_ALL_SPECIFIC_AMC_LIST}`, httpConfig);
      // console.log(response)
      // setAllAmc(response.data?.data);
      // setALLAmcIds(response.data?.data)
      // console.log(response.data.data, 'getSpecificAMC')
      const amcIds = response.data.data.map((item) => item.amcId);
      // console.log(amcIds, 'getSpecificAMC')
      setALLAmcIds(amcIds)
      // get_allFunds();
    } catch { }

  };


  useEffect(() => {
    // get_allFunds();

    // search funds by name 

    if (filterData.search_text) {
      // if (aLLAmcIds?.length > 0 && text?.length > 2) {
      //   get_data();
      // }

      const delayDebounce = setTimeout(() => {
        if (aLLAmcIds?.length > 0 && filterData?.search_text?.length > 2) {
          get_allSearchFunds();

        }
      }, 500); // Delay of 500ms

      // Cleanup the timeout if `text` or `aLLAmcIds` changes before the delay ends
      return () => clearTimeout(delayDebounce);

    } else {
      // if amc ids are there then only call ing the funds list 
      if (aLLAmcIds.length === 0) {
        get_all_amc_ids();
      }
      else {
        get_allFunds()

      }
    }
  }, [filterData, aLLAmcIds]);



  //----------------------------get all AMC-------------------------------------------------------------------//
  const get_all_amc = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      test: 'test',
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {

      const response = await httpInstance(`${GET_ALL_AMC}`, httpConfig);
      // console.log(response)
      setAllAmc(response.data?.data);
    } catch { }

  };

  // useEffect(() => {
  //   get_all_amc();
  // }, []);

  const selectedType = () => {
    let selectedAmcFundType;
    switch (type) {
      case 'All Fund':
        selectedAmcFundType = [
          {
            name: 'All Fund',
            amcCode: "top_rated",
            categoryId: null,
            // amt: 2210,
          },
        ]
        // returns.fund_1_year;
        break;
      case 'Trending':
        selectedAmcFundType = [
          {
            name: 'Trending',
            amcCode: "top_rated",
            categoryId: 0,
            // amt: 2210,
          },
        ]
        // returns.fund_1_year;
        break;
      case 'Focused Fund':
        selectedAmcFundType = [
          {
            name: 'Focused Fund',
            // amcCode: "sectoral_funds",
            amcCode: "focused",
            categoryId: 0
            // amt: 2210,
          },
        ];
        break;
      case 'Save Tax':
        selectedAmcFundType = [
          {
            name: 'Save Tax',
            amcCode: "tax_saver",
            categoryId: null
            // amt: 2210,
          },
        ];
        break;
      case 'Multi Cap':
        selectedAmcFundType = [
          {
            name: 'Multi Cap',
            amcCode: null,
            categoryId: 145
            // amt: 2210,
          },
        ]
        // returns.fund_1_year;
        break;
      case 'Balanced Fund':
        selectedAmcFundType = [
          {
            name: 'Balanced Fund',
            amcCode: 'balanced',
            categoryId: 0
            // amt: 2210,
          },
        ];
        break;
      case 'Liquid Fund':
        selectedAmcFundType = [
          {
            name: 'Liquid Fund',
            amcCode: "liquid_funds",
            categoryId: 125
            // amt: 2210,
          },
        ];
        break;
      case 'New Fund':
        selectedAmcFundType = [
          {
            name: 'New Fund Offering',
            amcCode: "new_fund",
            categoryId: null
            // amt: 2210,
          },
        ];
        break;
      default:
        console.log("Invalid index. Please provide a valid index (1, 2, or 3).");
        return []; // Return null or handle the error case appropriately
    }
    console.log(selectedAmcFundType, 'selectedAmcFundType')
    return selectedAmcFundType
  }




  const resetAll = () => {
    setAllFunds([]);
    setPage(0);
    setHasMore(true);
  };

  // ---------------------------------serach filter---------------------------------------------------------------//
  const search_filter = async (text) => {
    resetAll();
    if (/^[A-Za-z\s]*$/.test(text)) {
      setFilterData({ ...filterData, search_text: text });
    }
  };

  //--------------------------------AMC Filter-------------------------------------------------------------------//
  const amc_filter = (amc) => {
    resetAll();

    setFilterData({ ...filterData, amc_code: amc });
  };
  //-------------------------------Risk profile filter---------------------------------------------//
  const risk_filter = (risk) => {
    console.log(risk, 'risk')
    resetAll();
    setFilterData({ ...filterData, risk_profile: risk });
  };

  //--------------------------sub asset filter---------------------------------------------//
  const subAsset_filter = (asset) => {
    console.log(asset, 'assset')
    resetAll();
    setFilterData({ ...filterData, asset_sub_category: asset });
  };

  //------------------------------Rating filter--------------------------------------------//
  const rating_filter = (rate) => {
    resetAll();
    setFilterData({ ...filterData, ratings: rate });
  };

  //---------------------resset filter-----------------------------------//
  const reset_filter = () => {
    resetAll();
    setFilterData({});
  };

  // Search data
  const handle_search = (e) => {
    // if (/^[A-Za-z\s]+$/.test(e.target.value)) {
    // Valid input, continue with the search logic
    // ...
    search_filter(e.target.value);

    // } else {
    //   // Invalid input, prevent the input from being entered
    //   e.preventDefault();
    // }
  };

  return (
    <Fragment>
      <Box className={classes.header} px={{ xs: 0, md: 6 }}>
        <Container maxWidth="lg">
          {/* <Grid container spacing={4}> */}
          {/* <Grid item md={4}> */}
          <Paper className={classes.searchBar} variant="outlined">
            <InputBase
              className={classes.input}
              placeholder="Search for Mutual Funds"
              inputProps={{ "aria-label": "Search for Mutual Funds" }}
              onChange={handle_search}
            />
            <IconButton
              type="button"
              className={classes.iconButton}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Paper>

          <Funds_filter
            allAmc={allAmc}
            amc_filter={amc_filter}
            risk_filter={risk_filter}
            subAsset_filter={subAsset_filter}
            rating_filter={rating_filter}
            reset_filter={reset_filter}
            type={type}
          />
          {/* </Grid> */}
          {/* <Grid item md={8}> */}
          <List_funds
            allFunds={allFunds}
            get_allFunds={filterData.search_text ? get_allSearchFunds : get_allFunds}
            noMore={hasMore}
            search_filter={search_filter}
            filterData={filterData}
          />
          {/* </Grid> */}
          {/* </Grid> */}
        </Container>
      </Box>
    </Fragment>
  );
}

export default AllFunds;
