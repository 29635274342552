import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress, Box, Typography, Grid, Paper } from "@material-ui/core";
import httpInstance from "../../../utils/instance";
import { SEARCH_FUNDS, GET_ALL_AMC, SEARCH_FUNDS_BY_NAME, GET_ALL_SPECIFIC_AMC_LIST, GET_FUND_BASKET, GET_FUND_BASKET_BY_ID } from "../../../utils/endpoints";
import FundSkeleton from "../../../containers/Dashboard/Funds/CommonLoader/FundSkeleton";
import CustomToast from "../../UI/CustomToast";
import { Button, Skeleton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL, namespace } from "../../../environment";
import { Opacity } from "@mui/icons-material";
import CountUp from "react-countup";
import FundBasketFundDataCard from "./FundBasketFundComponent/FundBasketFundDataCard";
import FundBasketFundDataCardFundSplitting from "./FundBasketFundComponent/FundBasketFundDataCardFundSplitting";
import SipGoalsFundForm from "../../../containers/Goals/sipGoalsFund/SipGoalsFundForm";
import { useSelector } from "react-redux";
import FundBasketFundOTP from "./FundBasketFundComponent/FundBasketFundOTP";


const useStyles = makeStyles((theme) => ({
    searchBar: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        margin: "28px 0px 20px 0px",
        borderRadius: "10px",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        width: 100,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
    },
    SearchBarMargin: {
        marginTop: "0rem",
        // marginLeft: "2rem",
    },
    text: {
        marginTop: '20px',
        fontSize: '.8rem',
        color: 'grey',
        textAlign: "center",
    },
    boxDesign: {
        border: "1px solid rgb(212, 212, 212)",
        borderRadius: "10px",
        // maxWidth: "200px",
        width: "100%",
        // margin: "10px 0px",
        height: "70px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        "&:hover": {
            // boxShadow: "0px 0px 10px 0px rgb(97, 97, 97)",
            boxShadow: '0px 0px 5px 3px rgba(12,151,240,0.28)',
            border: "1px solid #0C97F0",
            // boxShadow: 'rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px'
        }
    },

    fundName: {
        fontSize: "1rem",
        padding: "10px",
        fontWeight: 600,
        textAlign: "center",
    },
    boxMain: {
        padding: "0.5rem 0.5rem",
    },
    headerFont: {
        fontSize: "1.1rem",
        fontWeight: "500",
    },
    "@media (max-width: 600px)": {
        SearchBarMargin: {
            marginLeft: "0rem",
        },
    },

    recommendedFoundAreaTopHeadText: {
        fontSize: '0.6rem',
        border: '1px solid #E4E4E4',
        backgroundColor: "#E4E4E4",
        padding: '0.2rem 0.3rem',
        borderRadius: "5px 5px 0px 0px",
        textTransform: "capitalize !important",
    },
    recommendedFoundArea: {
        border: '1px solid #E4E4E4',
        padding: '0.5rem',
        borderRadius: "10px",
        marginBottom: '10px',
    },
    rupees: {
        fontFamily: "Open Sans",
    },
    editDeleteIcon: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '10px',
        cursor: 'pointer',
        fontSize: '0.8rem',
        border: 'none',
        background: 'none',
        "&:disabled": {
            Opacity: 0.5
        }
    },
    editFundDataText: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '10px',
        cursor: 'pointer',
        fontSize: '0.8rem',
        color: "#0E76BC"
    },
    nav: {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "400",
        margin: 0,
        marginTop: '5px',
    },
    navValue: {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "600",
        margin: 0,
        marginTop: '5px',
    },
    perReturns: {
        fontFamily: "Poppins",
        color: "#0E76BC",
        fontSize: "16px",
        fontWeight: "600",
        margin: 0,
        marginTop: '5px',
    },
    yearReturns: {
        fontFamily: "Poppins",
        fontSize: "13px",
        fontWeight: "400",
        margin: 0,
        marginTop: '5px',
    },
    primaryButton: {
        marginTop: '10px',
        padding: "0.3rem 2rem",
        background: "#0E76BC",
        color: "#fff",
        fontSize: "1rem",
        fontWeight: 600,
        borderRadius: "45px",
        border: "none",
        cursor: "pointer",
        fontFamily: "inherit",
        transition: '0.2s ease',
        border: "1px solid #0E76BC",
        textAlign: 'center',
        "&:hover": {
            background: "#fff",
            color: "#0E76BC",
        },
        "&:disabled": {
            opacity: 0.5,
        }
    },
    backButton: {
        cursor: 'pointer',
    }
}));



const FundBasketFundData = () => {
    const classes = useStyles();
    const [allFunds, setAllFunds] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0)
    const [stepTwoData, setStepTwoData] = useState()
    const [investmentFund, setInvestmentFund] = useState()
    const { userStore, userKYCStore } = useSelector((state) => state);

    const navigate = useNavigate();

    const { state } = useLocation();

    const get_allFunds = async (data) => {
        setHasMore(true)
        setLoading(true)
        const httpConfig = {
            method: "GET",
            tokenType: "user",
            // test: 'test',
            headers: {
                "Content-Type": "application/json",
            },
            // data: { ...formData, ...filterData },
            // data: { ...formData },
            params: { model_portfolio_id: data?.id }


        };
        const httpConfig1 = {
            method: "GET",
            tokenType: "user",
            // test: 'test',
            headers: {
                "Content-Type": "application/json",
            },
            params: { model_portfolio_id: 'id' }
        };
        // console.log(httpConfig)

        try {
            const response = await httpInstance(`${GET_FUND_BASKET_BY_ID}`, httpConfig);
            // const responsenext = await httpInstance (`${GET_ALL_AMC}`)
            // console.log(response.data.data)
            setAllFunds([...allFunds, ...response.data?.data]);
            setHasMore(false);
            setLoading(false)
            //   if (response.data?.responseCount == 1) {
            //     setHasMore(false);
            //   } else {
            //     setAllFunds([...allFunds, ...response.data?.data]);
            //     set_page();
            //   }
        } catch (error) {
            CustomToast(error.response.data.message ?? error.response.data.error ?? "Something went wrong", 'FAILED')
            setLoading(false)
            // setHasMore(false);
        }
    };


    useEffect(() => {

        // get_allFunds();
        // console.log(state)
        if (state?.fundData?.id) {
            get_allFunds(state?.fundData)
        }
    }, [state]);



    const fetchData = () => {
        get_allFunds();
    };




    const handleFundBusket = (fund) => {
        console.log(fund)
    }

    useEffect(() => {
        if (state?.fundData) {
            // console.log(state)
        } else {
            navigate(
                `${namespace}/mutualfund/fund-basket`,

            );
        }
    }, [state])


    const handleInvest = () => {
        // console.log(allFunds)
    }

 
    

    const handleInvestContinue = (data) => {
        // console.log(data)
        setInvestmentFund(data)
        setStep(2)
    }

    return (
        <div>
            <Box px={{ xs: 2, md: 14 }}>
                <Box mt={4} mb={2} display={'flex'} alignItems={"center"}>
                    {step === 1 &&
                        <Box
                            display="flex"
                            justifyContent="flex-start"
                            m={0}
                            className={classes.backButton}
                            onClick={() => setStep(0)}
                        >
                            <img src="/images/backIcon.svg" alt="back" height={20} width={20} />
                        </Box>
                    }
                    <Typography variant="h5" style={{ textAlign: "start", paddingLeft: '10px' }} className={classes.headerFont}>Fund Basket</Typography>
                </Box>
                {/* Fund Basket Step 1 */}
                {step === 0 &&
                    <FundBasketFundDataCard allFunds={allFunds} fetchData={fetchData} hasMore={hasMore} classes={classes} handleInvest={handleInvest} loading={loading} setStep={(dataStep, data) => {
                        setStep(dataStep)
                        setStepTwoData(data)
                    }
                    } />
                }
                {step === 1 &&
                    <>
                        <FundBasketFundDataCardFundSplitting allFunds={stepTwoData} hasMore={hasMore} classes={classes} handleInvest={handleInvestContinue} loading={loading} setStep={(data) => setStep(data)} />
                    </>
                }
                {step === 2 &&
                    <>
                        <FundBasketFundOTP
                            fundData={investmentFund}
                            // fpData={fpData}
                            kycStatus={userKYCStore?.userKyc?.fp_kyc_status}
                            // show_banner={show_banner}
                            kycData={userKYCStore?.userKyc}
                            fundTransactionBasket={investmentFund?.basket?.transaction_basket_items}
                            handleStepOTP={()=>{}}
                            handleStepsOTP={step}
                            state={state?.fundData}
                        />
                    </>
                }
            </Box >
        </div >
    )
}
export default FundBasketFundData;