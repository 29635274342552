import React, { useState } from "react";
import { Box, makeStyles, CircularProgress, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import axios from 'axios'
import httpInstance from "../../../../../utils/instance";
import CustomToast from "../../../../../components/UI/CustomToast";
import { GET_FUND_BASKET_BY_ID_AND_TYPE, GET_PORTFOLIO_FUND_CHANGEDDATA_DISTRIBUTE } from "../../../../../utils/endpoints";
import { useLocation } from "react-router-dom";

function OneTimeAmountForm({ setModal, handleShowSuccess, selectedFund }) {

  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "2rem 12rem",
      position: "fixed",
      top: 0,
      zIndex: 30,
      backdropFilter: "grayscale(0.2) blur(1px)"
    },
    innerContainer: {
      borderRadius: "1rem"
    },
    heading: {
      color: "#272727",
      fontSize: "1.2rem"
    },
    paragraph: {
      fontSize: "0.9rem",
      fontWeight: "normal",
      color: "#474747",
      lineHeight: "1.4rem"
    },
    label: {
      color: "#272727",
      fontSize: "0.8rem"
    },
    inputText: {
      // background: "#e6e6e6",
      fontSize: "0.9rem",
      border: "none",
      borderBottom: "1px solid #000",
      borderRadius: "0px",
      padding: "0.6rem 0.4rem",
      outline: 'none',
    },
    messageInputText: {
      fontSize: "0.82rem",
      background: "#e6e6e6",
      border: "none",
      borderRadius: "10px",
      padding: "0.6rem 0.4rem",
      height: "6rem",
      resize: "none",
      fontFamily: "inherit"

    },
    buttonHolder: {
      width: "50%",
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      justifyContent: "start",
      padding: "0px 0px 1rem 0px",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "0px 710px 0px 0px"
    },
    button: {
      border: "none",
      borderRadius: "40px",
      background: "#0E76BC",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff",
      padding: "0.7rem 3rem",
      cursor: "pointer",
      width: 'fit-content',
    },
    borderedButton: {
      border: "1px solid #0E76BC",
      borderRadius: "40px",
      background: "#fff",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      color: "#0E76BC",
      padding: "0.5rem 3rem",
      cursor: "pointer"
    },
    closeBtn: {
      cursor: "pointer"
    },

    error: {
      color: "#cf1020",
      fontSize: "0.8rem"
    },

    mobileError: {
      display: "none"
    },

    circularProgress: {
      height: "10px",
      width: "10px"
    },
    rupeesSymbol: {
      position: 'relative',
      right: '2px',
      top: '4px',
      width: '10px',
      // marginRight: '5px',
      fontSize: '13px',
      fontFamily: "Open Sans",

    },

    "@media (max-width: 900px)": {
      button: {
        padding: "0.7rem 1.4rem",
      },
      borderedButton: {
        padding: "0.5rem 1.4rem",
      }
    },

    "@media (max-width: 468px)": {
      mobileError: {
        display: "block",
        color: "#cf1020",
        fontSize: "0.8rem",
        marginTop: "0px"
      },

      error: {
        display: "none"
      }
    }

  }));
  const classes = useStyles();
  //--------------------------------------------------------------------------------------------//
  const [loading, setLoading] = useState(false)
      const { state } = useLocation();
  
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    
  } = useForm();
  const [amountError, setAmountError] = useState('')

  const handleFormSubmit = async (data) => {
    // console.log(data, selectedFund)
    if (data.amount < 5000) {
      setAmountError("Amount have to be more than ₹5000")
    } else {

      setLoading(true)
      setAmountError("")
      setLoading(true)
      const httpConfig = {
        method: "GET",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          model_portfolio_id: state?.fundData?.id,
          duration: 100,
          amount: Number(data.amount),
          is_onetime: true
        },
        // data: formData
      };


      try {
        const responseReturn = await httpInstance(`${GET_FUND_BASKET_BY_ID_AND_TYPE}`, httpConfig);
        // const response = await httpInstance(`${POST_GOALS_CALCULATE_MY_FIRST_GOAL}`, httpConfig1);
        // console.log(response?.data, 'fadfadfadsfasdfasdfasdf')
        setLoading(false)
        setModal(responseReturn?.data?.data)
        handleShowSuccess()
        CustomToast("Sucessfully upadated", "SUCCESS")

      } catch (error) {
        console.log(error.response.data?.error)
        setAmountError(error.response.data?.error)
        CustomToast(error?.response?.data?.error ?? error?.response?.data?.message ?? "Something went wrong", "FAILED")

      } finally {
        setLoading(false)

      }

    }


  }

  // change fund data api call
  const handleFundChange = async (fund, items) => {

    // try {
    //     const responseReturn = await httpInstance(`${GET_PORTFOLIO_FUND_DISTRIBUTE}`, httpConfig);
    //     console.log(responseReturn);
    //     // const response = await httpInstance(`${POST_GOALS_CALCULATE_MY_FIRST_GOAL}`, httpConfig1); 
    //     // console.log(response?.data, 'fadfadfadsfasdfasdfasdf') 
    //     setLoading(false);
    // }
    // catch (error) {
    //     console.log(error.response.data?.error);
    //     setErrorMessage(error.response.data?.error);
    // }
    // finally { setLoading(false); }
  }
  // console.log(selectedFund)

  


  return (

    <Box className={classes.innerContainer}>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent="space-between"
        flexDirection="row"
        width="100%"
      >
        <Box className={classes.paragraph}>
          Change Amount
        </Box>
        <Box onClick={() => setModal(false)}>
          <img
            src="/images/Icon.svg"
            height="16"
            width="16"
            className={classes.closeBtn}
          />
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" mt={4} width="100%">
        <Box width="100%">
          {/* <Box display={'flex'} alignItems={"center"} justifyContent={'start'}>
            <img src={selectedFund?.fund?.scheme_logo} height='40px' width="40px" alt="logo" style={{ marginRight: "10px" }} />
            <Typography variant="body" >

              {selectedFund?.fund?.name}
            </Typography>
          </Box> */}
          {/* <Box className={classes.heading}>Feel free to write to us</Box> */}
          <Box display="flex" flexDirection="column" mt={2} mr={3}>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <Box width="100%" display="flex" mb={1} sx={{ flexDirection: { xs: "column", md: "column" } }}>


                <Box display={"flex"} flexDirection="column" mb={2} sx={{ width: { xs: "100%", md: "100%" }, marginRight: { xs: "0px", md: "0px" } }}>
                  <label className={classes.label}>Select Type</label>
                  <select type="select"
                    placeholder="Enter the amount"
                    className={classes.inputText}
                    py={1}
                    {...register("selectType", {
                      required: true,
                      // pattern: {
                      //   value: /^[0-9]+$/i,
                      //   message: "Only numbers are allowed",
                      // },
                    })}
                    defaultValue={"one_time" }
                    onChange={(e) => {
                      // Sanitize input: allow only numbers
                      // const numericValue = e.target.value.replace(/[^0-9]/g, "");
                      setValue("selectType", e.target.value, { shouldValidate: true });
                    }}
                  >

                    <option value="">Select Type</option>
                    <option value="one_time">One Time</option>
                  </select>
                  {errors.selectType && (errors.selectType.type === "required" ? (
                    <p className={classes.error}>*Fill this field</p>
                  ) : (
                    <p className={classes.error}>{errors.selectType.message}</p>
                  ))}

                </Box>
                <Box display={"flex"} flexDirection="column" sx={{ width: { xs: "100%", md: "100%" } , marginLeft: { xs: "0px", md: "0px" }  }}>
                  <label className={classes.label}>Enter The Amount</label>
                  <Box height={'0px'}><span className={classes.rupeesSymbol} style={{ marginRight: '5px' }}>₹</span></Box>

                  <input type="text"
                    placeholder="Enter the amount"
                    inputmode="numeric"
                    className={classes.inputText}
                    py={1}
                    {...register("amount", {
                      required: true,
                      pattern: {
                        value: /^[0-9]+$/i,
                        message: "Only numbers are allowed",
                      },
                    })}
                    onChange={(e) => {
                      // Sanitize input: allow only numbers
                      const numericValue = e.target.value.replace(/[^0-9]/g, "");
                      setValue("amount", numericValue, { shouldValidate: true });
                    }}
                  />
                  {errors.amount && (errors.amount.type === "required" ? (
                    <p className={classes.error}>*Fill this field</p>
                  ) : (
                    <p className={classes.error}>{errors.amount.message}</p>
                  ))}

                </Box>
              </Box>



              <Box display="flex" justifyContent='center' flexDirection={'column'} alignItems={'center'}>
                {amountError &&
                  <p className={classes.error}>{amountError}</p>
                }
                <button
                  className={classes.button}
                  type="submit"
                >
                  {loading ? <CircularProgress size="1rem" color="inherit" /> : "Proceed"}
                </button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>

    </Box>

  );
}

export default OneTimeAmountForm;
