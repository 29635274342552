import { combineReducers } from "redux";
import userReducers from '../User/userReducers'
import userKYCReducers from '../User/userKYCReducers'
import AdminReducers from "../Admin/AdminUser/userReducers";
import notifiReducers  from "../Notification/NotifiReducers";
import admiNoticationReducers from "../Admin/AdminNotification/AdminNotifiReducers"


const appReducer = combineReducers({
  userStore: userReducers,
  userKYCStore: userKYCReducers,
  adminStore:AdminReducers,
  notificationStore:notifiReducers,
  adminNotification:admiNoticationReducers
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;