import React, { useEffect, useState } from "react";
import { Box, Typography, makeStyles, Grid, Slider } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { POST_GOALS_CALCULATE_MY_FIRST_GOAL } from "../../utils/endpoints";
import Kyc_status from "../Dashboard/Funds/KycStatusCheck/Kyc_status";
import { namespace, BASE_URL } from "../../environment";
import MyFirstCrore from "./MyFirstCrore";
import TaxSavings from "./TaxSavings";
import EmergencyFund from "./EmergencyFund";
import ParkYourSpareMoney from "./ParkYourSpareMoney";
import LongTermWealth from "./LongTermWealth";
import RetirementPlan from "./RetirementPlan";
import PrincipalProtection from "./PrincipalProtection";


const SpecificGoal = () => {
    const useStyles = makeStyles((theme) => ({
        root: {},
        mainGrid: {
            padding: "30px 4rem 0px 8rem",
        },
        subTabtext: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
            fontWeight: 500,
        },
        textUnderline: {
            fontSize: "0.7rem",
            textDecoration: "underline",
            fontFamily: "inherit",
        },
        text: {
            fontSize: "0.8rem",
            fontWeight: 700,
        },
        subText: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
            fontWeight: 500,
            paddingLeft: '5px',
        },
        text1: {
            fontSize: "0.8rem",
        },
        text2:{
            fontSize: "1.2rem",
            fontFamily: "inherit",
            fontWeight: 500,
            // paddingLeft: '5px', 
        },
        text3:{
            fontSize: "1.2rem",
            fontFamily: "inherit",
            fontWeight: 700,
            // paddingLeft: '5px', 
        },
        textBold: {
            fontSize: "0.8rem",
            fontWeight: 700,
            marginBottom: "1rem",
        },

        thumb: {
            background: "#bdbdbd",
            width: "20px",
            height: "20px",
            boxShadow: 'none !important',
            ' &.Mui-focusVisible': {
                boxShadow: 'none !important',
            },
            '&.Mui-active': {
                boxShadow: 'none !important',
            },
            // boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
        },
        mark: {
            background: "black"
        },
        rail: {
            background: "#EBEBEB",
            height: "10px",
            borderRadius: '10px',
        },
        track: {
            background: "#0E76BC",
            height: "10px",
            borderRadius: '10px',
        },
        borderBox: {
            // border: "1px solid #E4E4E4",
            // borderRadius: "5px",
            // padding: "1.5rem",
            // width: "75%",
            '& .MuiSlider-valueLabel': {
                '& > *': {

                    backgroundColor: 'white',
                    color: "black"
                },
            }
        },
        rupees: {
            fontFamily: "Open Sans"
        },
        primaryButton: {
            padding: "0.8rem 5rem",
            background: "#0E76BC",
            color: "#fff",
            fontSize: "0.8rem",
            fontWeight: 600,
            borderRadius: "45px",
            border: "none",
            cursor: "pointer",
            fontFamily: "inherit"

        },
        "@media (max-width: 767px)": {
            mainGrid: {
                padding: "0px 10px 0px 10px",
            },
        },
    }));
    const classes = useStyles();
    const location = useLocation();

    const [goalsSelection, setGoalsSelection] = useState(null)
    const [sliderValueOnChangeEnd, setSliderValueOnChangeEnd] = useState(5000)

    const [fundData, setFundData] = useState(null);
    const [fundDataPerformance, setFundDataPerformance] = useState(null);
    const [fpData, setFpData] = useState();
    const [showBanner, setShowBanner] = useState();
    const [loading, setLoading] = useState(false);
    const [holdingDataOfSectors, setHoldingDataOfSectors] = useState([])
    // const [folioNumber, setFolioNumber]= useState('')

    const { variant } = useParams();
    const { state } = useLocation()
    const navigate = useNavigate();

    useEffect(() => {
        if (variant === "My First Crore") {
            setGoalsSelection(0)
        } else if (variant === "Tax Saving") {
            setGoalsSelection(1)

        }
        else if (variant === "Park Your Spare Money") {
            setGoalsSelection(2)

        } else if (variant === "Retirement") {
            setGoalsSelection(3)

        } else if (variant === "Long term wealth") {
            setGoalsSelection(4)

        } else if (variant === "Principal Protection") {
            setGoalsSelection(5)

        } else if (variant === "Emergency Fund") {
            setGoalsSelection(6)

        } else {
            navigate({
                pathname: `${namespace}/goals`,
                // search: `?${createSearchParams({
                //   tab: "value",
                // //   category: "diy",
                // })}`,
            });
        }
    }, [variant]);




    return (
        <div className={classes.root}>
            <Box
                display="flex"
                px={{ xs: 1, md: 17 }}
                pt={2}
                // style={{ borderBottom: "1px solid #E6E3E3" }}
            >
                {/* <Link
                    to={`${namespace}/goals`}
                    style={{ color: "black" }}
                >
                    <Typography className={classes.textUnderline}>
                        Goals
                    </Typography>
                </Link>
                <Box display="flex">
                    <ChevronRightIcon style={{ height: "1rem" }} />
                    <Typography
                        className={classes.subTabtext}
                        display="flex"
                        alignItems="start"
                    >
                      
                        {variant}
                    </Typography>
                </Box> */}
            </Box>
            {/* kyc banner */}
            {showBanner && <Kyc_status />}
            {/* <Box
                display="flex"
                justifyContent="flex-start"
                px={{ xs: 2, md: 15 }}
                mt={5}
                className={classes.backButton}
            >
                <Link to={`${namespace}/goals`}>
                    <img src="/images/backIcon.svg" height="auto" width="20px" />
                </Link>
            </Box> */}
            <Box
                // display="flex"
                // px={{ xs: 1, md: 15 }}
                // pt={2}
            // style={{ borderBottom: "1px solid #E6E3E3" }}
            >
                {goalsSelection == 0 &&
                    <>
                        {/*  My First Crore Tab */}

                        <MyFirstCrore  stateData={state} />
                    </>
                }
                {goalsSelection == 1 &&
                    <>
                        {/*  Tax Saivngs Tab */}

                        {/* < Typography > Tax Saivngs </Typography> */}
                        <TaxSavings  stateData={state} />
                    </>
                }
                {goalsSelection == 2 &&
                    <>
                        {/*  Park Your Spare Money Tab */}

                        <ParkYourSpareMoney  stateData={state} />
                    </>
                }
                {goalsSelection == 3 &&
                    <>
                        {/*  Retirement Tab */}

                        <RetirementPlan  stateData={state} />
                    </>
                }
                {goalsSelection == 4 &&
                    <>
                        {/*  Long Term Wealth Tab */}

                        {/* < Typography > Long Term Wealth </Typography> */}
                        <LongTermWealth  stateData={state} />
                    </>
                }
                {goalsSelection == 5 &&
                    <>
                        {/*  Principal Protection Tab */}
                        {/* < Typography > Principal Protection </Typography> */}
                        <PrincipalProtection stateData={state} />

                    </>
                }
                {goalsSelection == 6 &&
                    <>
                        {/*  Emergency Fund Tab */}

                        {/* < Typography > Emergency Fund </Typography> */}
                        <EmergencyFund stateData={state} />
                    </>
                }
            </Box>


        </div >
    );
};

export default SpecificGoal;
