import {
    GET_USER_KYC
  } from "./userActionTypes";

  const userInitialState = {
    userKyc:[],
    loading: false,
    error: null,
    
  };

  const wishlistKYCReducers = (state = userInitialState, action) => {
    switch (action.type) {
      case GET_USER_KYC: {
        return { ...state, userKyc: action.payload, loading: action?.payload ? false : true, error: null };
      }
      default: {
        return state;
      }
    }
  };
  
  export default wishlistKYCReducers;