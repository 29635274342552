import React from "react";
import { Box, makeStyles, Typography, Grid } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import moment from "moment";
import localeString from "../../../hooks/LocaleString";
import LocaleStringTwoMin from "../../../hooks/LocaleStringTwoMin";

const OrderList = ({ orderData, type, }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      // borderBottom: "1px solid #DDDDDD",
      //borderRadius: "5px",
      borderRadius: "12px",
      // boxShadow: "0 0 8px -3px #444444",
      border: '1px solid #E4E4E4',
      // boxShadow: '0 0 8px -3px #444444',
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
      padding: "0.7rem",
    },
    header: {
      fontSize: "0.85rem",
    },
    greyText: {
      fontSize: "0.85rem",
      color: "#202020",
    },
    successText: {
      color: "#6FDDB8",
      fontSize: "0.8rem",
      textAlign: "end",
      fontWeight: 500,
      padding: "0px 10px",
      textTransform: "uppercase",
    },
    rupees: {
      fontFamily: "Open Sans",
    },
    failedText: {
      color: "#F27E45",
      fontSize: "0.8rem",
      textAlign: "end",
      fontWeight: 500,
      padding: "0px 10px",
      textTransform: "uppercase",
    },
    pendingText: {
      color: "#F2AF1A",
      fontSize: "0.8rem",
      textAlign: "end",
      fontWeight: 500,
      padding: "0px 10px",
      textTransform: "uppercase",
    },
  }));
  const classes = useStyles();


  // console.log(orderData)
  return (
    <div>
      <Box className={classes.root} p={2} mt={1}>
        <Grid container>
          <Grid item xs={12} md={7} lg={7}>
            <Box display="flex" alignItems="center">
              <img src={orderData?.switch_out_fund_logo_url ? orderData?.switch_out_fund_logo_url : orderData?.logo_url} height="auto" width="40px" style={{ borderRadius: '10px', }} />
              <Box pl={2} display="flex" alignItems="start" flexDirection={'column'}>
                <Typography className={classes.header} style={{ fontWeight: '600' }}>
                  {orderData?.fund_name
                    ? orderData?.fund_name
                    : orderData?.switch_out_fund_name ?  orderData?.switch_out_fund_name : orderData?.fundName }
                </Typography>
                <Typography className={classes.header}>
                  {
                    type ?
                      <>
                        {orderData?.folio_number ?
                          <>
                            <span style={{ fontWeight: '300' }}>Folio number:</span>{" "}
                            {orderData?.folio_number}
                          </>
                          :
                          <>
                            <span style={{ fontWeight: '300' }}>Fund ISIN:</span>{" "}
                            {orderData?.fund_isin}
                          </>
                        }
                      </>
                      :
                      orderData?.scheme ?
                        <>
                          <span style={{ fontWeight: '300' }}>Scheme Id:</span>{" "}
                          {orderData?.scheme}
                        </>
                        :
                        <>
                          <span style={{ fontWeight: '300' }}>Folio Number:</span>{" "}
                          {orderData?.folio_number}
                        </>

                  }
                </Typography>
              </Box>
              {/* <Box className={classes.type} style={{color:orderTypeColor[ele?.orderType],background:`${orderTypeColor[ele?.orderType]}80`}}>{orderTypeObj[ele?.orderType]}</Box> */}
            </Box>
          </Grid>

          <Grid item xs={6} md={3} lg={3} alignItems="center" display="flex">

            <Box display="flex" alignItems="center" justifyContent={'center'} flexDirection={'row'}>
              <Box px={1}>
                <span className={classes.header} style={{ fontWeight: '300', fontSize: '0.7rem' }}>Amount:</span>{" "}
                <Typography className={classes.header}>
                  <span className={classes.rupees}>₹</span>{" "}
                  {orderData?.amount && LocaleStringTwoMin(Number(orderData?.amount))}
                </Typography>
              </Box>
              <Box px={1}>
                <span className={classes.header} style={{ fontWeight: '300', fontSize: '0.7rem' }}>Units:</span>{" "}
                <Typography className={classes.header}>
                  {orderData?.units ? orderData?.allotted_units : orderData?.units || '--'}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={6} md={2} lg={2}>
            <Box display="flex" justifyContent="end" alignItems="center">
              <Box pr={2}>
                <Typography className={classes.greyText}>
                  {orderData?.created_at ?
                    moment(orderData?.created_at).format("DD MMM YYYY") : `${orderData?.installment_day} ${orderData?.frequency}`}
                </Typography>


                <Typography
                  className={
                    (orderData?.state == "successful" || orderData?.status == "active")
                      ? classes.successText
                      : orderData?.status == "pending"
                        ? classes.pendingText
                        : classes.failedText
                  }
                >
                  {orderData?.state || orderData?.status}
                </Typography>
              </Box>
              <ChevronRightIcon style={{ cursor: "pointer" }} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default OrderList;
