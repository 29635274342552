import { Box, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BASE_URL } from '../../../../environment';
import CountUp from 'react-countup';

const FundBasketFundDataCardFundSplitting = ({ allFunds, fetchData, hasMore, classes, handleInvest, loading, }) => {

    const LoaderComp = () => {
        return (
            <>

                {[1, 2, 3, 4, 5]?.map((fund, i) => {
                    return (
                        <Box >
                            <Box ml={2} className={classes.recommendedFoundAreaTopHead}>
                                <Skeleton variant="text" width={'100px'} />
                            </Box>
                            <Box className={classes.recommendedFoundArea}>

                                {/* {items.fund_isin} */}
                                <Box className={classes.recommendedFoundAreaLeft}
                                    display={'flex'} alignItems={"center"}
                                    justifyContent="space-between"
                                >
                                    <Box display={'flex'} alignItems={"center"} width={'100%'}>

                                        <Skeleton variant="rounded" border width={50} height={50} />
                                        <Skeleton variant="text" width={'60%'} />

                                    </Box>

                                    <Skeleton variant="text" width={'100px'} />


                                </Box>
                                <Grid item xs={12} container className={classes.cardContent2}>
                                    {[1, 2, 3, 4, 5, 6].map((key, index) => (
                                        <Grid key={index} item xs={5} sm={3} md={2}>
                                            <p className={classes.navValue}>
                                                <Skeleton variant="text" width={80} sx={{ fontSize: '1.2rem' }} />
                                            </p>
                                            <Skeleton variant="text" width={50} />
                                        </Grid>
                                    ))}
                                </Grid>
                                {/* <Box display={'flex'} alignItems={"center"}
                                    justifyContent="end">
                                    <Skeleton variant="text" width={'100px'} />


                                </Box> */}
                            </Box>
                        </Box>
                    )
                })}
            </>
        );
    };

    // const handleDelete = (fund, item, i) => {
    //     console.log(fund, item, i)
    // }
    const [stateFund, setStateFund] = useState()
    useEffect(() => {
        setStateFund(allFunds)
    }, [allFunds])

    const handleDelete = (fund, items, index) => {
        // console.log(fund, items)
        const newFundDetails = { ...stateFund?.fund_details };
        delete newFundDetails[items.fund_isin];
        // // Remove from transactionBasketItems 
        const newTransactionBasketItems = stateFund?.basket?.transaction_basket_items.filter((data) => data.fund_isin !== items.fund_isin);
        // Update the state correctly
        setStateFund({
            ...stateFund,
            fund_details: newFundDetails,
            basket: {
                ...stateFund.basket,  // Preserve other properties inside basket
                transaction_basket_items: newTransactionBasketItems, // Update only this key
            },
        });
    }

    // console.log(stateFund)


    return (
        <div>
            {stateFund &&
                <div >
                    <InfiniteScroll
                        style={{
                            overflow: "hidden"
                        }}
                        dataLength={stateFund?.basket?.transaction_basket_items?.length}
                        next={fetchData}
                        hasMore={hasMore}
                        loader={<LoaderComp />}
                        endMessage={
                            <Typography className={classes.text}>
                                {/* No More Funds to display */}
                            </Typography>
                        }
                    >

                        {stateFund?.basket?.transaction_basket_items?.map((items, i) => {
                            return (
                                <>

                                    <Box key={i}>
                                        <Box ml={2} className={classes.recommendedFoundAreaTopHead}>
                                            <Typography variant="body" className={classes.recommendedFoundAreaTopHeadText}>{stateFund?.fund_details[items.fund_isin]?.fund_category}: {stateFund?.fund_details[items.fund_isin]?.plan_type}</Typography>
                                        </Box>
                                        <Box className={classes.recommendedFoundArea}>

                                            {/* {items.fund_isin} */}
                                            <Box className={classes.recommendedFoundAreaLeft}
                                                display={'flex'} alignItems={"center"}
                                                justifyContent="space-between"
                                            >
                                                <Box display={'flex'} alignItems={"center"}>
                                                    <img src={stateFund?.fund_details[items.fund_isin]?.scheme_logo} height='40px' width="40px" alt="logo" style={{ marginRight: "10px" }} />
                                                    <Typography variant="body" >

                                                        {stateFund?.fund_details[items.fund_isin]?.name}
                                                    </Typography>
                                                </Box>

                                                {/* {fundDetails[items.fund_isin]?.name} */}
                                                <Typography variant="body" className={classes.text1}>
                                                    <span className={classes.rupees}>₹</span>
                                                    {items.amount?.toLocaleString('en-IN')}
                                                </Typography>

                                            </Box>
                                            <Box display={'flex'} alignItems={"center"}
                                                justifyContent="end">
                                                {/* {console.log(state?.goalData)} */}
                                                {/* {!(state?.goalData?.name === "Principal Protection") &&
                                                <Typography variant="body" className={classes.editDeleteIcon} onClick={
                                                    () => open_contact(allFunds[items.fund_isin], items)
                                                }>
                                                    <img src='/images/editIcon.svg' height={15} width={15} alt="" />
                                                    Edit
                                                </Typography>
                                            } */}
                                                <Box className={classes.editDeleteIcon} onClick={() => handleDelete(stateFund?.fund_details[items.fund_isin], items, i)}>
                                                    <img src='/images/deleteIcon.svg' height={15} width={15} alt="" />
                                                    Remove
                                                </Box>
                                            </Box>
                                            {/* Your content here */}
                                        </Box>
                                    </Box>

                                </>
                            )
                        })}
                    </InfiniteScroll>

                </div>
            }
            {(stateFund?.basket?.transaction_basket_items.length === 0 && !loading) && <Typography variant="body1" style={{ textAlign: 'center', marginTop: '20px', fontSize: '0.7rem' }}>No Funds Found</Typography>}

            <Box display={'flex'} justifyContent={'center'} >
                <button className={classes.primaryButton} onClick={()=>handleInvest(stateFund)} disabled={stateFund?.basket?.transaction_basket_items?.length === 0}>
                    Invest
                </button>
            </Box>
        </div >
    );
};

export default FundBasketFundDataCardFundSplitting;